import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import './CatalogItem.css';
import session from './Session';
import { Modal, Box, Link, Typography } from '@mui/material';
import ThemeSwitch from './ThemeSwitch';

const CatalogItem = ({ item, addItem, isDriver, showPoints }) => {
    const [showDetailedModal, setShowDetailedModal] = useState(false);
    const [pointDollarValue, setPointDollarValue] = useState(0);
    const [isInCart, setIsInCart] = useState(false);
    const { sponsor_id } = useParams();

    // Modal Style
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // Add and Remove from Cart
    async function AddToCart() {
        var tempCart = session.getShoppingCart();
        const itemIndex = tempCart.Cart.findIndex(cartItem => cartItem.item_name === item.item_name);
        if (itemIndex === -1) {
            var tempObject = { ...item };
            tempObject.price = Math.ceil(tempObject.price / pointDollarValue)
            tempCart.Cart.push(tempObject);
            session.setShoppingCart(tempCart);
            setIsInCart(true);
        }
    }

    async function RemoveFromCart() {
        const tempCart = session.getShoppingCart();
        const itemIndex = tempCart.Cart.findIndex(cartItem => cartItem.item_name === item.item_name);
        if (itemIndex !== -1) {
            tempCart.Cart.splice(itemIndex, 1);
            session.setShoppingCart(tempCart);
            setIsInCart(false);
        }
    }

    useEffect(() => {
        async function checkInCart() {
            const result = await inCart();
            setIsInCart(result);
        }

        async function getPointDollarValue() {
            try {
                const response = await fetch(`${session.getDomain()}/api/catalog/getSponsorPointDollarValue/${sponsor_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                const data = await response.json();
                setPointDollarValue(data.point_dollar_value);
            } catch (e) {
                console.error("Error fetching point dollar value:", e);
            }
        }
        checkInCart();
        if (isDriver) {
            getPointDollarValue();
        }
    }, [item]);

    async function inCart() {
        const tempCart = session.getShoppingCart().Cart;
        return tempCart.some(cartItem => cartItem.item_name === item.item_name);
    }

    const handleCardClick = async () => {
        if (item.inCatalog) {
            // To Do: handle item removal from catalog
        } else {
            try {
                const response = await fetch(`${session.getDomain()}/api/catalog/addCatalogItem`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ name: item.item_name, price: item.price, image: item.image_url, url: item.item_url, sponsor_id: sponsor_id }),
                });
                const result = await response.json();
                if (response.ok) {
                    alert("Item added to catalog");
                    addItem(result);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleDriverCardClick = async () => {
        const result = await inCart();
        setIsInCart(result);
        setShowDetailedModal(true);
    }

    const handleCloseDetailedModal = () => {
        setShowDetailedModal(false);
    }

    const placeholderImage = 'https://via.placeholder.com/150';
    return (
        <>
            <div
                className="card mb-3"
                style={{
                    maxWidth: '800px',
                    cursor: 'pointer',
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '20px',
                    boxSizing: 'border-box',
                    margin: '20px auto',
                    borderRadius: '15px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    border: 'none',
                    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                    border: `2px solid var(--border-color)`,
                    backgroundColor: 'var(--background-color-light)',
                    color: 'var(--text-color-light)',
                }}
                onClick={isDriver ? handleDriverCardClick : handleCardClick}
                onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = 'var(--hover-on)';
                    e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.15)';
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = 'var(--hover-off)';
                    e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                }}
            >
                <div style={{
                    flex: '0 0 150px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '20px'
                }}>
                    <img
                        src={item.image_url ? item.image_url : placeholderImage}
                        alt={item.item_name}
                        style={{
                            maxHeight: '150px',
                            maxWidth: '150px',
                            objectFit: 'contain'
                        }}
                    />
                </div>
                <div style={{ flex: '1', overflow: 'hidden' }}>
                    <h5 className="card-title" style={{ margin: '0 0 10px 0', fontSize: '1.5rem', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        {showPoints ? (
                                <>
                                    <b>{item.item_name} ---- {Math.ceil(item.price / pointDollarValue)} points</b>
                                </>
                            ) : (
                                <>
                                    <b>{item.item_name} ---- ${item.price}</b>
                                </>
                            )}
                    </h5>
                </div>
                {!isDriver && (
                    <>
                        {item.inCatalog && (<button className="remove-item-button">x</button>)}
                        {!item.inCatalog && (<button className="add-item-button">+</button>)}
                    </>
                )}
            </div>

            {isDriver && (
                <Modal
                    open={showDetailedModal}
                    onClose={handleCloseDetailedModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <img
                            src={item.image_url ? item.image_url : placeholderImage}
                            alt={item.item_name}
                            style={{
                                width: '100%',
                                height: 'auto',
                                marginBottom: '20px'
                            }}
                        />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {item.item_name}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {showPoints ? (
                                <>
                                    <b>Price:</b> {Math.ceil(item.price / pointDollarValue)} points
                                </>
                            ) : (
                                <>
                                    <b>Price:</b> ${item.price}
                                </>
                            )}
                        </Typography>
                        {item.description && (
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <b>Description:</b> {item.description}
                            </Typography>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Link href={item.item_url} underline="always">
                                {'View on Ebay'}
                            </Link>
                            {isInCart ? (
                                <button className='CartBTN' onClick={RemoveFromCart}>
                                    <span style={{
                                        fontSize: '36px',
                                        color: 'red'
                                    }} className="material-symbols-outlined">remove_shopping_cart</span>
                                </button>
                            ) : (
                                <button className='CartBTN' onClick={AddToCart}>
                                    <span style={{
                                        fontSize: '36px',
                                        color: 'green'
                                    }} className="material-symbols-outlined">add_shopping_cart</span>
                                </button>
                            )}
                        </div>
                    </Box>
                </Modal>
            )}
        </>
    );
};

CatalogItem.propTypes = {
    item: PropTypes.shape({
        image_url: PropTypes.string.isRequired,
        item_name: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
        item_url: PropTypes.string.isRequired,
        description: PropTypes.string,
        inCatalog: PropTypes.bool
    }).isRequired,
    addItem: PropTypes.func,
    isDriver: PropTypes.bool.isRequired,
    showPoints: PropTypes.bool.isRequired
};

export default CatalogItem;
