import React, { useState } from 'react';
import './AddRewardsPlanModal.css';

const AddRewardsPlanModal = ({ closeModal, addRewardsPlan }) => {
    const [points, setPoints] = useState('');
    const [interval, setInterval] = useState(1);

    const handleSubmit = (e) => {
        e.preventDefault();
        addRewardsPlan({ point_value: points, payment_interval: interval });
        closeModal();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close" onClick={closeModal}>x</button>
                <h2>Add Rewards Plan</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="points" className="form-label">Points:</label>
                        <input
                            type="number"
                            id="points"
                            value={points}
                            onChange={(e) => setPoints(e.target.value)}
                            required
                            className="form-input"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="interval" className="form-label">Interval:</label>
                        <select
                            id="interval"
                            value={interval}
                            onChange={(e) => setInterval(Number(e.target.value))}
                            className="form-select"
                        >
                            <option value={1}>Weekly</option>
                            <option value={2}>Biweekly</option>
                            <option value={4}>Monthly</option>
                            <option value={12}>Annually (Workaversay)</option>
                        </select>
                    </div>
                    <div className="modal-buttons">
                        <button type="button" onClick={closeModal} className="cancel-button">Cancel</button>
                        <button type="submit" className="submit-button">Add Plan</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddRewardsPlanModal;
