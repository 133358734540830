import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Session from '../components/Session';
import './DriverApplicationInbox.css';

const DriverApplicationInbox = () => {
    const { sponsor_id } = useParams();
    const [applications, setApplications] = useState([]);
    const [selectedApplication, setSelectedApplication] = useState(null);

    useEffect(() => {
        fetchApplicationDetails();
    }, [sponsor_id]);

    const fetchApplicationDetails = async () => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/driverApplications/pending/${sponsor_id}`);
            const data = await response.json();
            setApplications(data);
        } catch (error) {
            console.error('Error fetching applications:', error);
        }
    };

    const handleApplicationClick = (application) => {
        setSelectedApplication(application);
    };

    const createBlobUrl = (base64Data, mimeType) => {
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeType });
        return URL.createObjectURL(blob);
    };

    const handleApprove = async () => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/driverApplications/approve`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', 
                },
                body: JSON.stringify({
                    driver_application_id: selectedApplication.driver_application_id,
                    sponsor_id: sponsor_id, 
                    user_id: selectedApplication.user_id, 
                }),
            });

            if (response.ok) {
                alert('Application approved successfully.');
                setSelectedApplication(null);
                fetchApplicationDetails();
            } else {
                throw new Error('Failed to approve application.');
            }
        } catch (error) {
            console.error('Error approving application:', error);
            alert('Failed to approve application.');
        }
    };


    const handleReject = async () => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/driverApplications/reject`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    driver_application_id: selectedApplication.driver_application_id,
                    sponsor_id: sponsor_id,
                    user_id: selectedApplication.user_id,
                }),
            });

            if (response.ok) {
                alert('Application rejected successfully.');
                setSelectedApplication(null);
                fetchApplicationDetails();
            } else {
                throw new Error('Failed to reject application.');
            }
        } catch (error) {
            console.error('Error rejecting application:', error);
            alert('Failed to reject application.');
        }
    };

    return (
        <>
            <Header />
            <div className="inbox-container">
                <div className="inbox-list">
                    {applications.length > 0 ? (
                        applications.map((app) => (
                            <div
                                key={app.driver_application_id}
                                className="application-card"
                                onClick={() => handleApplicationClick(app)}
                            >
                                <p className="application-username">{app.username}</p>
                                <p className="application-date">{new Date(app.date_submitted).toLocaleDateString()}</p>
                            </div>
                        ))
                    ) : (
                        <div>No applications found</div>
                    )}
                </div>
                <div className="inbox-details">
                    {selectedApplication ? (
                        <div className="application-details">
                            <h2 className="application-details-header">Application Details</h2>
                            <p><b>Username:</b> {selectedApplication.username}</p>
                            <p><b>Date Submitted:</b> {new Date(selectedApplication.date_submitted).toLocaleDateString()}</p>
                            <div className="file-viewer">
                                <p>
                                    <strong>License:</strong>
                                    <a
                                        href={createBlobUrl(selectedApplication.license, 'image/png')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        <u>Open in new tab</u>
                                    </a>
                                </p>
                                <img
                                    src={`data:image/png;base64,${selectedApplication.license}`}
                                    alt="License"
                                    style={{ width: '100%', maxHeight: '300px' }}
                                />
                                <p>
                                    <strong>Resume:</strong>
                                    <a
                                        href={createBlobUrl(selectedApplication.resume, 'application/pdf')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        <u>Open in new tab</u>
                                    </a>
                                </p>
                                <iframe
                                    src={`data:application/pdf;base64,${selectedApplication.resume}`}
                                    width="100%"
                                    height="200px"
                                    title="Resume"
                                />
                            </div>
                            <div className="action-buttons">
                                <button onClick={handleApprove} className="approve-button">Approve</button>
                                <button onClick={handleReject} className="reject-button">Reject</button>
                            </div>
                        </div>
                    ) : (
                        <div className="placeholder-text">Select an application to view details</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DriverApplicationInbox;
