import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Session from '../components/Session';
import SponsorCard from '../components/SponsorCard';

import './DriverApplicationList.css';

function DriverApplicationList() {
    const [sponsors, setSponsors] = useState([]);

    useEffect(() => {
        const fetchSponsors = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/driverApplications/sponsors`);
                const data = await response.json();
                setSponsors(data);
            } catch (error) {
                console.error('Error fetching sponsors:', error);
            }
        };
        fetchSponsors();
    }, []);

    return (
        <>
            <Header />
            <div className="admin-sponsors-container">
                <div className="sponsor-list-container">
                    <div className="sponsor-orgs-header">Sponsors</div>
                    <div className="sponsor-list">
                        {sponsors.map((sponsor) => (
                            <SponsorCard
                                key={sponsor.sponsor_id}
                                sponsor={sponsor}
                                link={`/driverApplications/${sponsor.sponsor_id}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DriverApplicationList;