import React, { useState, useEffect } from 'react';
import Session from './Session';

import LightDarkContrast from '../assets/LightDarkContrast.png';

function ThemeSwitch() {
  const [theme, setTheme] = useState(Session.getTheme() || 'light'); // Default to light if no theme is set

  useEffect(() => {
    const root = document.documentElement;
    switch (theme) {
      case 'dark':
        root.style.setProperty('--background-color-light', '#333');
        root.style.setProperty('--text-color-light', '#fff');
        root.style.setProperty('--header-bg-light', '#222');
        root.style.setProperty('--form-bg-light', '#444');
        root.style.setProperty('--border-color', '#444');
        root.style.setProperty('--hover-on', '#444')
        root.style.setProperty('--hover-off', '#333')
        break;
      case 'high-contrast':
        root.style.setProperty('--background-color-light', 'black');
        root.style.setProperty('--text-color-light', 'white');
        root.style.setProperty('--header-bg-light', 'blue');
        root.style.setProperty('--form-bg-light', 'yellow');
        root.style.setProperty('--border-color', 'yellow');
        root.style.setProperty('--hover-on', 'blue')
        root.style.setProperty('--hover-off', 'black')
        break;
      default: // light mode
        root.style.setProperty('--background-color-light', 'white');
        root.style.setProperty('--text-color-light', 'black');
        root.style.setProperty('--header-bg-light', '#00b4e8');
        root.style.setProperty('--form-bg-light', 'rgb(147, 222, 237)');
        root.style.setProperty('--border-color', '#00b4e8');
        root.style.setProperty('--hover-on', '#f0f0f0')
        root.style.setProperty('--hover-off', 'white')
        break;
    }
  }, [theme]);

  // Function to cycle through themes
  const toggleTheme = () => {
    let newTheme;
    if (theme === 'light') {
      newTheme = 'dark';
    } else if (theme === 'dark') {
      newTheme = 'high-contrast';
    } else {
      newTheme = 'light';
    }

    setTheme(newTheme);
    Session.setTheme(newTheme); // Store the selected theme in Session
  };

  return (
    <div className="theme-switch">
      <img
        src={LightDarkContrast}
        alt="Toggle Theme"
        onClick={toggleTheme}
        style={{ cursor: 'pointer', width: '50px', height: '50px' }}
      />
    </div>
  );
}

export default ThemeSwitch;

