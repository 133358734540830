import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const SponsorCard = ({ sponsor, link }) => {
    const navigate = useNavigate();
    const handleCardClick = () => {
        navigate(link);
    };
    const placeholderImage = 'https://via.placeholder.com/150';

    return (
        <div
            className="card mb-3"
            style={{
                maxWidth: '540px',
                cursor: 'pointer',
                height: '100px',
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                boxSizing: 'border-box',
                margin: '15px',
                borderRadius: '15px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                border: 'none',
                transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
            }}
            onClick={handleCardClick}
            onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#f0f0f0';
                e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.15)';
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '';
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
            }}
        >
            <div style={{
                flex: '0 0 80px',
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                left: '10px'
            }}>
                <img
                    src={sponsor.logo ? sponsor.logo : placeholderImage}
                    alt={sponsor.sponsor_name}
                    style={{
                        maxHeight: '70px',
                        maxWidth: '70px',
                        objectFit: 'contain'
                    }}
                />
                <h5 className="card-title" style={{ margin: 15 }}>
                    <b>{sponsor.sponsor_name}</b>
                </h5>
            </div>
        </div>
    );
};

SponsorCard.propTypes = {
    sponsor: PropTypes.shape({
        sponsor_id: PropTypes.string.isRequired,
        sponsor_name: PropTypes.string.isRequired,
        logo: PropTypes.string,
    }).isRequired,
    link: PropTypes.string.isRequired,
};

export default SponsorCard;
