import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Session from '../components/Session';
import { useNavigate } from 'react-router-dom';

import './DriverApplicationForm.css';

const DriverApplicationForm = () => {
    const navigate = useNavigate();
    const user_id = Session.getUser()?._userId;
    const { sponsor_id } = useParams();
    const [sponsor, setSponsor] = useState(null);
    const [licenseImage, setLicenseImage] = useState(null);
    const [resumePdf, setResumePdf] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false); 

    useEffect(() => {
        const fetchSponsorDetails = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/driverApplications/sponsors/${sponsor_id}`);
                const data = await response.json();
                setSponsor(data);
            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }
        };
        fetchSponsorDetails();
    }, [sponsor_id]);

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files[0]) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                if (name === 'licenseImage') {
                    setLicenseImage(base64String);
                } else if (name === 'resumePdf') {
                    setResumePdf(base64String);
                }
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const applicationData = {
            user_id,
            sponsor_id,
            licenseImage, // This is now a base64 string
            resumePdf,    // This is now a base64 string
        };

        try {
            const response = await fetch(`${Session.getDomain()}/api/driverApplications/apply`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(applicationData),
            });

            if (response.ok) {
                setIsSubmitted(true); 
            } else {
                alert('Error submitting application');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleBackToSponsors = () => {
        setIsSubmitted(false);
        navigate('/driverApplications');
    }

    if (!sponsor) {
        return <div>Loading sponsor details...</div>;
    }

    return (
        <>
            <Header />
            <div className="driver-application-container">
                <h1 className="driver-application-title">DRIVE FOR {sponsor.sponsor_name}</h1>
                <form onSubmit={handleSubmit}>
                    <div className="driver-application-field">
                        <label htmlFor="licenseImage" className="driver-application-label">Upload Driver's License:</label>
                        <input
                            type="file"
                            name="licenseImage"
                            accept="image/*"
                            onChange={handleFileChange}
                            required
                            className="driver-application-input"
                        />
                    </div>

                    <div className="driver-application-field">
                        <label htmlFor="resumePdf" className="driver-application-label">Upload Resume (PDF):</label>
                        <input
                            type="file"
                            name="resumePdf"
                            accept="application/pdf"
                            onChange={handleFileChange}
                            required
                            className="driver-application-input"
                        />
                    </div>

                    <button type="submit" className="driver-application-button">Submit Application</button>
                </form>
            </div>

            {/* Modal for successful submission */}
            {isSubmitted && (
                <div className="submitted-modal">
                    <div className="submitted-modal-content">
                        <h2 className="submitted-modal-title">Application to Drive for {sponsor.sponsor_name} Submitted Successfully!</h2>
                        <button className="driver-application-button" onClick={handleBackToSponsors}>
                            Back to Sponsor List
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default DriverApplicationForm;
