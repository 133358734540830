import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './pages/App';
import Sign from './pages/SignIn';
import Catalog from './pages/Catalog';
import Support from './pages/Support';
import Inbox from './pages/Inbox';
import About from './pages/About';
import AccountCreation from './pages/AccountCreation';
import Profile from './pages/Profile';
import Report from './pages/Report';
import OrderHistory from './pages/OrderHistory';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Admin from './pages/Admin';
import AdminSponsors from './pages/AdminSponsors';
import SponsorDetails from './pages/SponsorDetails';
import ManageSponsor from './pages/ManageSponsor';
import ManageSponsorDrivers from './pages/ManageSponsorDrivers';
import ManageSponsorUsers from './pages/ManageSponsorUsers'; 
import CatalogManagement from './pages/CatalogManagement';
import AdminEditPage from './pages/AdminEditPage';
import ManageDriverRewardsPlans from './pages/ManageDriverRewardsPlans'; 
import DriverPerspective from './pages/DriverPerspective';
import AdminReport from './pages/AdminReport';
import Checkout from './pages/Checkout';



import DriverApplicationList from './pages/DriverApplicationList';
import DriverApplicationForm from './pages/DriverApplicationForm';
import DriverApplicationInbox from './pages/DriverApplicationInbox';
import reportWebVitals from './reportWebVitals';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>

    <Router>
      <Routes>
        
        <Route path="/" element={<App />} />

        <Route path="/About" element={<About />} />
        <Route path="/AccountCreation" element={<AccountCreation />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/report" element={<AdminReport />} />
        <Route path="/admin/sponsors" element={<AdminSponsors />} />
        <Route path="/admin/sponsors/:sponsor_id" element={<SponsorDetails />} />
        <Route path="/Catalog/:sponsor_id" element={<Catalog />} />
        <Route path="/driverApplications" element={<DriverApplicationList />} />
        <Route path="/driverApplications/:sponsor_id" element={<DriverApplicationForm />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/Inbox" element={<Inbox />} />
        <Route path="/manage/sponsor/:sponsor_id" element={<ManageSponsor />} />
        <Route path="/manage/sponsor/:sponsor_id/catalog/" element={<Catalog />} />
        <Route path="/manage/sponsor/:sponsor_id/catalogManagement/" element={<CatalogManagement />} />
        <Route path="/manage/sponsor/:sponsor_id/driverApplicationInbox" element={<DriverApplicationInbox/> } />
        <Route path="/manage/sponsor/:sponsor_id/sponsorDrivers" element={<ManageSponsorDrivers />} />
        <Route path="/manage/sponsor/:sponsor_id/sponsorUsers" element={<ManageSponsorUsers />} />
        <Route path="/manage/sponsor/:sponsor_id/rewardsPlans" element={<ManageDriverRewardsPlans />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/signin" element={<Sign />} />
        <Route path="/manage/sponsor/:sponsor_id/Report" element={<Report />} />
        <Route path="/Support" element={<Support />} />
        <Route path="/AdminEditPage" element={<AdminEditPage />} />
        <Route path="/OrderHistory" element={<OrderHistory />} />
        <Route path="/manage/sponsor/:sponsor_id/DriverPerspective" element={<DriverPerspective />} />
        <Route path="/checkout" element={<Checkout />} />

      </Routes>
    </Router>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
