// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-sponsors-container {
    display: flex;
    align-items: flex-start;
    padding: 20px;
}

.sponsor-list-container {
    width: auto;
    min-width: 500px;
    margin-right: 20px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 150px;
    margin-left: 15px;
}

.sponsor-orgs-header {
    margin-left: 30px;
    max-width: 800px;
    font-size: 2.0rem;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0px;
    text-transform: uppercase;
}


.sponsor-list {
    max-height: 330px;
    width: auto;
    min-width: 550px;
    overflow-y: auto;
    padding: 10px;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/DriverApplicationList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,kBAAkB;IAClB,yBAAyB;AAC7B;;;AAGA;IACI,iBAAiB;IACjB,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,cAAc;IACd,wCAAwC;IACxC,mBAAmB;AACvB","sourcesContent":[".admin-sponsors-container {\n    display: flex;\n    align-items: flex-start;\n    padding: 20px;\n}\n\n.sponsor-list-container {\n    width: auto;\n    min-width: 500px;\n    margin-right: 20px;\n}\n\n.buttons-container {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    max-width: 150px;\n    margin-left: 15px;\n}\n\n.sponsor-orgs-header {\n    margin-left: 30px;\n    max-width: 800px;\n    font-size: 2.0rem;\n    font-weight: bold;\n    color: #333;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    margin-bottom: 0px;\n    text-transform: uppercase;\n}\n\n\n.sponsor-list {\n    max-height: 330px;\n    width: auto;\n    min-width: 550px;\n    overflow-y: auto;\n    padding: 10px;\n    margin: 20px 0;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
