const AccountType = require('../enums/enums');

class User {
    constructor(userId, username, email, accountType,TFA,profilePicture) {
        this._userId = userId; 
        this._username = username;
        this._email = email;
        this._accountType = accountType;
        this._TFA = TFA;
        this._profilePicture = profilePicture

    }

    get userId() {
        return this._userId; 
    }
    
    get username() {
        return this._username;
    }

    get email() {
        return this._email;
    }

    get accountType() {
        return this._accountType;
    }

    get TFA() {
        return this._TFA;
    }

    get profilePicture() {
        return this._profilePicture;
    }
}

export default User;