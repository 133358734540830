// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout-container {
    padding: 20px;
  }
  
  .checkout-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .checkout-list {
    list-style-type: none;
    padding: 0;
  }
  
  .checkout-item {
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
  }
  
  .checkout-item:hover {
    background-color: #f0f0f0;
  }
  
  .CatalogItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .item-text {
    flex-grow: 1;
  }
  
  .item-image {
    max-width: 50px;
    max-height: 50px;
    margin-left: 10px;
  }
  
  .remove-button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 10px;
    display: none;
    position: absolute;
    right: 10px;
  }
  
  .checkout-item:hover .remove-button {
    display: block;
  }
  
  .remove-button:hover {
    background-color: darkred;
  }
  
  .total-price {
    text-align: right;
    margin-top: 20px;
  }
  
  .submit-order {
    text-align: center;
    margin-top: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Checkout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,sCAAsC;IACtC,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":[".checkout-container {\n    padding: 20px;\n  }\n  \n  .checkout-title {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .checkout-list {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  .checkout-item {\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    position: relative;\n  }\n  \n  .checkout-item:hover {\n    background-color: #f0f0f0;\n  }\n  \n  .CatalogItem {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n  \n  .item-text {\n    flex-grow: 1;\n  }\n  \n  .item-image {\n    max-width: 50px;\n    max-height: 50px;\n    margin-left: 10px;\n  }\n  \n  .remove-button {\n    background-color: red;\n    color: white;\n    border: none;\n    padding: 5px 10px;\n    cursor: pointer;\n    margin-left: 10px;\n    display: none;\n    position: absolute;\n    right: 10px;\n  }\n  \n  .checkout-item:hover .remove-button {\n    display: block;\n  }\n  \n  .remove-button:hover {\n    background-color: darkred;\n  }\n  \n  .total-price {\n    text-align: right;\n    margin-top: 20px;\n  }\n  \n  .submit-order {\n    text-align: center;\n    margin-top: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
