import React, { useState, useEffect } from 'react';
import Session from '../components/Session';
import './RemoveSponsorOrgModal.css';

function RemoveSponsorOrgModal({ onClose }) {
    const [sponsors, setSponsors] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedSponsor, setSelectedSponsor] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchSponsors = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors`);
                if (!response.ok) {
                    throw new Error('Failed to fetch sponsors.');
                }
                const data = await response.json();
                setSponsors(data);
            } catch (error) {
                console.error('Error fetching sponsors:', error);
                setError('Could not fetch sponsor organizations. Please try again later.');
            }
        };
        fetchSponsors();
    }, []);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setError('');
        setSelectedSponsor(null); 
    };

    const handleSponsorSelect = (sponsor) => {
        setSelectedSponsor(sponsor);
        setSearch(sponsor.sponsor_name);
        setError('');
    };

    const handleRemove = async () => {
        if (!selectedSponsor) {
            setError('Please select a sponsor to remove.');
            return;
        }

        try {
            setLoading(true);
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/removeSponsor`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sponsor_id: selectedSponsor.sponsor_id }),
            });

            if (!response.ok) {
                throw new Error('Failed to remove sponsor.');
            }

            setSponsors((prev) =>
                prev.filter((sponsor) => sponsor.sponsor_id !== selectedSponsor.sponsor_id)
            );
            setSelectedSponsor(null); 
            setError('');
            alert('Sponsor removed successfully!');
            onClose(); 
        } catch (error) {
            console.error('Error removing sponsor:', error);
            setError('Failed to remove the sponsor. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const filteredSponsors = sponsors.filter((sponsor) =>
        sponsor.sponsor_name.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div className="remove-sponsor-org-modal-overlay" onClick={onClose}>
            <div className="remove-sponsor-org-modal" onClick={(e) => e.stopPropagation()}>
                <button className="remove-sponsor-org-modal-close-btn" onClick={onClose}>X</button>
                <div className="remove-sponsor-org-modal-header">Remove Sponsor Organization</div>

                <input
                    type="text"
                    id="sponsor-search"
                    placeholder="Search sponsor organizations..."
                    value={search}
                    onChange={handleSearchChange}
                    className="remove-sponsor-org-search"
                />

                {!selectedSponsor && (
                    <div className="remove-sponsor-org-list">
                        {filteredSponsors.map((sponsor) => (
                            <div
                                key={sponsor.sponsor_id}
                                className={`remove-sponsor-org-item ${selectedSponsor?.sponsor_id === sponsor.sponsor_id ? 'selected' : ''}`}
                                onClick={() => handleSponsorSelect(sponsor)}
                            >
                                {sponsor.sponsor_name}
                            </div>
                        ))}
                    </div>
                )}

                {error && <p className="error-message">{error}</p>}

                <button
                    className="remove-sponsor-org-submit-btn"
                    onClick={handleRemove}
                    disabled={!selectedSponsor || loading}
                >
                    {loading ? 'Removing...' : 'Remove Sponsor'}
                </button>
            </div>
        </div>
    );
}

export default RemoveSponsorOrgModal;
