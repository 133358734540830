import React from 'react';
import logo from '../assets/trucklogo.png';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import session from '../components/Session';
import ThemeSwitch from '../components/ThemeSwitch';
import User from '../models/user';


function ForgotPassword() {

    const sendForgetEmail = async (event) => {

        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
    
        try {
          const response = await fetch(`${session.getDomain()}/api/authentication/forgotpassword`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(Object.fromEntries(formData.entries()))
          });
    
          const result = await response.json();
          console.log(result);
    
          if (result.found === true) {
            // We have that email stored in our DB as a user
            alert("Email sent! Check your inbox.")
          }
          else {
            alert("No Email found on file.")
          }
    
        } catch (e) {
          console.error('Error:', e);
        }
    
      };

    return (
    <>
        <Header />
        <form id="signForm" onSubmit={sendForgetEmail}>
        <img className="mb-4" src={logo} alt="Logo" width="72" height="57" />
        <h1 className="h3 mb-3 fw-normal">Please Enter Your Email</h1>

        <div className="form-floating">
            <input name="email" autoComplete='off' type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
            <label htmlFor="floatingInput">Email</label>
        </div>

        <button className="btn btn-primary w-100 py-2" type="submit">Submit</button>
        <p className="mt-5 mb-3 text-body-secondary">© 2017–2024</p>
        </form>

    </>

    );
}

export default ForgotPassword;
