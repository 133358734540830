import React from 'react';
import PropTypes from 'prop-types';

const RewardsPlanCard = ({ plan, color, onClick }) => {
    const { point_value, payment_interval } = plan;

    const intervalText = {
        1: 'week',
        2: '2 weeks',
        4: 'month'
    };

    return (
        <div
            className="card mb-3"
            style={{
                width: '300px',
                height: '100px',
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                boxSizing: 'border-box',
                margin: '15px',
                borderRadius: '15px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                border: 'none',
                backgroundColor: color,
                cursor: 'pointer',
                transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
            }}
            onClick={() => onClick(plan)} 
            onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.15)';
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
            }}
        >
            <div style={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <h5 style={{ margin: 0, fontWeight: 'bold' }}>{point_value} pts</h5>
                <p style={{ margin: 0 }}>{point_value} pts / {intervalText[payment_interval]}</p>
            </div>
        </div>
    );
};

RewardsPlanCard.propTypes = {
    plan: PropTypes.shape({
        point_value: PropTypes.number.isRequired,
        payment_interval: PropTypes.oneOf([1, 2, 4]).isRequired,
    }).isRequired,
    color: PropTypes.string,
    onClick: PropTypes.func.isRequired, 
};

export default RewardsPlanCard;
