import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import ThemeSwitch from '../components/ThemeSwitch';
import session from '../components/Session';
import CatalogItem from '../components/CatalogItem';
import { Modal, TextField, Button, Box } from '@mui/material';

import "../styles/catalogmanagement.css";


function CatalogManagement() {
    const [items, setItems] = useState([]);
    const [editItem, setEditItem] = useState(null);
    const [isCatalogListOpen, setisCatalogListOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const { sponsor_id } = useParams();
    const userClass = session.getUser()?._accountType;


    // Edit modal constants
    const nameRef = useRef();
    const descriptionRef = useRef();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    // Fetch catalog items on page load
    useEffect(() => {
        async function fetchCatalogItems() {
            try {
                const response = await fetch(`${session.getDomain()}/api/catalog/getCatalogItems/${sponsor_id}`);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setItems(data);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        }

        fetchCatalogItems();
    }, [sponsor_id]);

    const handleOpenCatalogList = () => {
        setisCatalogListOpen(true);
    };

    const handleCloseCatalogList = () => {
        setisCatalogListOpen(false);
    };

    const handleRemoveItem = async (catalog_item_id) => {
        try {
            const response = await fetch(`${session.getDomain()}/api/catalog/removeCatalogItem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    catalogItemId: catalog_item_id
                }),
            });

            const data = await response.json();

            if (data.found) {
                // remove the item from local list
                setItems(items.filter((item) => item.catalog_item_id !== catalog_item_id));
            }
            else if (!data.found) {
                console.error('Item not found');
            }
            else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const handleEditItem = (item) => {
        setEditItem(item);
    };

    const ebaySearch = async (searchQuery) => {

        try {
            const response = await fetch(`${session.getDomain()}/api/ebay/search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ search: searchQuery }),
            });
            const result = await response.json();
            const parsedResult = JSON.parse(result);

            // Only get relevant info and return if the item is already in the catalog
            const returnedItems = parsedResult.findItemsByKeywordsResponse[0].searchResult[0].item.map(item => ({
                price: item.sellingStatus[0].currentPrice[0].__value__,
                item_name: item.title[0],
                item_url: item.viewItemURL[0],
                image_url: item.galleryURL[0],
                inCatalog: items.some(catalogItem => catalogItem.url === item.viewItemURL[0])
            }));
            setSearchResults(returnedItems);

        } catch (error) {
            console.error(error);
        }

    };

    const handleSearch = () => {
        ebaySearch(searchQuery);
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                handleSearch();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [searchQuery]);

    function addItem(newItem) {
        setItems([...items, newItem]);
    }

    const handleEditSubmit = async () => {
        const editedItem = { ...editItem, item_name: nameRef.current.value, description: descriptionRef.current.value };

        try {
            const response = await fetch(`${session.getDomain()}/api/catalog/editCatalogItem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedItem),
            });

            const result = await response.json();
            setItems(items.map(item => item.catalog_item_id === result.catalog_item_id ? result : item));
            setEditItem(null);
            alert('Item updated successfully');
        } catch (error) {
            console.error(error);
        }
    };

    if (userClass !== 2 && userClass !== 3) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <h1>Page Not Found</h1>
            </div>
        );
    }

    return (
        <>
            <Header />
            <h1 style={{ textAlign: "center" }}>Catalog Management</h1>
            <div id="button-container">
                <button className="view-catalog-button" onClick={handleOpenCatalogList}>View your Catalog</button>
            </div>


            <div id='searchContainer'>
                <input
                    type='text'
                    placeholder='Search for items on Ebay'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button className="view-catalog-button" onClick={handleSearch}>Search</button>
            </div>

            <div id='catalogContainer'>
                {searchResults.length > 0 && (
                    <div id='searchResults'>
                        <h2>Search Results</h2>
                        <ul>
                            {searchResults.map((item, index) => (
                                <CatalogItem key={index} item={item} addItem={addItem} isDriver={false} showPoints={false} />
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            {isCatalogListOpen && (
                <div className="modal open">
                    <div className="modal-overlay" onClick={handleCloseCatalogList}></div>
                    <div className="modal-content">
                        <button className="modal-close" onClick={handleCloseCatalogList}>x</button>
                        <h2>Catalog</h2>
                        <ul className="catalog-list">
                            {items.map((item) => (
                                <li key={item.catalog_item_id}>
                                    {item.item_name} - ${item.price}
                                    <button className="remove-item-button" onClick={() => handleRemoveItem(item.catalog_item_id)}>x</button>
                                    <button className="edit-item-button" onClick={() => handleEditItem(item)}>Edit</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}

            {editItem && (
                <Modal open={true} onClose={() => setEditItem(null)}>
                    <Box sx={style}>
                        <div className="edit-modal-content">
                            <h2>Edit Item</h2>
                            <TextField
                                label="Name"
                                name="item_name"
                                defaultValue={editItem.item_name}
                                inputRef={nameRef}
                                margin="normal"
                            />
                            <TextField
                                label="Description"
                                name="description"
                                defaultValue={editItem.description}
                                inputRef={descriptionRef}
                                margin="normal"
                            />
                            <Button className="submit-edit-button" variant="contained" color="success" onClick={handleEditSubmit}>Save</Button>
                        </div>
                    </Box>
                </Modal>
            )}

        </>
    );
}

export default CatalogManagement;