import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Session from '../components/Session';

import './Profile.css';

function Profile() {
    const [backendData, setBackendData] = useState({});
    const [isEditing, setIsEditing] = useState({ username: false, email: false, password: false });
    const [formData, setFormData] = useState({ username: '', email: '' });
    const [PointHistory, setPointHistory] = useState(null);
    const [getSponsors, setSponsors] = useState([]);
    const [sponsorIncentives, setSponsorIncentives] = useState(null);
    const userId = Session.getUser()?._userId;
    const userClass = Session.getUser()?._accountType;
    const userTFA = Session.getUser()?._TFA;
    const logged = Session.getLoggedIn();


    useEffect(() => {
        if (userId && userClass) {
            fetch(`${Session.getDomain()}/api/profile?userId=${encodeURIComponent(userId)}&userClass=${encodeURIComponent(userClass)}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.found === false) {
                        alert('Profile not found.');
                    } else {
                        setBackendData(data);
                        // Set form data based on user class
                        if (userClass === 1) {
                            // form data for drivers 
                            setFormData({
                                username: data.username,
                                email: data.email,
                                driver_points: Session.getDriverUser()._points || '',
                                sponsor_name: Session.getDriverUser()._sponsorName || '',
                                sponsor_id: Session.getDriverUser()._sponsorId || ''

                            });
                        } else {
                            // form data for non-drivers
                            // will customize for sponsors / admins later 
                            setFormData({
                                username: data.username,
                                email: data.email
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error fetching profile:', error);
                });
        }




        if (userClass === 1) {

            var sponsorID = Session.getDriverUser()._sponsorId;


            fetch(`${Session.getDomain()}/api/profile/getPointHistory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userID: userId, sponsorID: sponsorID })
            })
                .then(response => response.json())
                .then(result => {
                    setPointHistory(result)
                })
                .catch(e => {
                    console.error('Error:', e);
                    alert('An error occurred while updating the profile.');
                });




            fetch(`${Session.getDomain()}/api/profile/getSponsors`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userID: Session.getUser()._userId })
            })
                .then(response => response.json())
                .then(result => {
                    setSponsors(result)
                })
                .catch(e => {
                    console.error('Error:', e);
                    alert('An error occurred while updating the profile.');
                });


            fetch(`${Session.getDomain()}/api/admin/sponsors/getSponsorIncentives`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ sponsor_id: sponsorID })
            })
                .then(response => response.json())
                .then(result => {
                    setSponsorIncentives(result)
                })
                .catch(e => {
                    console.error('Error:', e);
                    alert('An error occurred while updating the profile.');
                });





        }






        // //Addition
        // const fetchPoints = async () => {
        //     try {
        //       const response = await fetch(`${Session.getDomain()}/api/driver_points`, {
        //         method: 'GET',
        //         headers: {
        //           'Content-Type': 'application/json',
        //           'Authorization': `Bearer ${Session.getUser().token}`,
        //         },
        //       });
        //       const data = await response.json();
        //       setDriverPoints(data.points); // Update the driver points state
        //     } catch (error) {
        //       console.error('Failed to fetch points:', error);
        //     }
        //   };

        //   if (userId && userClass) {
        //     fetchPoints();
        //   }



    }, [userId, userClass, logged]);

    const toggleEdit = (field) => {
        setIsEditing((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const updateField = async (fieldName) => {

        try {
            const response = await fetch(`${Session.getDomain()}/api/profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ [fieldName]: formData[fieldName], field: fieldName, userId, userClass })
            });

            const result = await response.json();

            if (response.ok) {
                alert(result.message || `${fieldName} updated successfully!`);
                setBackendData(result.user || backendData);
                toggleEdit(fieldName);
                if (fieldName === 'username') {
                    let storedUser = Session.getUser();
                    storedUser._username = result.user.username;
                    Session.setUser(storedUser);

                }
                if (fieldName === 'email') {
                    let storedUser = Session.getUser();
                    storedUser._email = result.user.email;
                    Session.setUser(storedUser);
                }
            } else {
                alert(result.message || `Failed to update ${fieldName}.`);
            }
        } catch (e) {
            console.error('Error:', e);
            alert('An error occurred while updating the profile.');
        }
    };

    async function toggleTFA(choice) {
        try {
            const response = await fetch(`${Session.getDomain()}/api/profile/TFA`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user: userId, OptChoice: choice })
            });

            const result = await response.json();

            if (response.ok) {
                alert('TFA updated successfully!');
                const user = Session.getUser();
                user._TFA = choice;
                Session.setUser(user);

                window.location.reload();

            } else {
                alert(result.message || `Failed to update TFA`);
            }
        } catch (e) {
            console.error('Error:', e);
            alert('An error occurred while updating the profile.');
        }
    }



    async function updateSetSponsor() {

        var selectedSponsor = document.getElementById("selectSponsor").value;
        var tempUser = Session.getDriverUser();
        for (let i = 0; i < getSponsors.length; i++) {
            if (getSponsors[i].sponsor_id == selectedSponsor) {
                tempUser._sponsorId = getSponsors[i].sponsor_id;
                tempUser._points = getSponsors[i].driver_points;
                tempUser._driverId = getSponsors[i].driver_id;
                tempUser._sponsorName = getSponsors[i].sponsor_name;
                Session.setDriverUser(tempUser);

                setFormData((prev) => ({
                    ...prev,
                    sponsor_id: getSponsors[i].sponsor_id,
                    sponsor_name: getSponsors[i].sponsor_name,
                    driver_points: getSponsors[i].driver_points,
                }));

                break;
            }
        }
        window.location.reload();
    }




    const handlePasswordSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        const password = form.password.value;
        const confirmPassword = form.confirmPassword.value;

        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        updatePassword(password, userId);
    };

    async function updatePassword(password, userId) {
        try {
            const response = await fetch(`${Session.getDomain()}/api/authentication/resetpasswordloggedin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ password: password, userId: userId })
            });

            const result = await response.json();

            if (response.ok) {
                alert('Password updated successfully!');

            } else {
                alert(result.message || `Failed to update password`);
            }
        } catch (e) {
            console.error('Error:', e);
            alert('An error occurred while updating the profile.');
        }
    }


    async function uploadImage(e) {
        e.preventDefault();
        const input = document.getElementById("profilePicture").files[0];
        if (input.size > 2097152) {
            alert("File must be less than 2MB")
        }
        else {
            const reader = new FileReader();
            if (input) {
                reader.readAsDataURL(input);
            }
            reader.addEventListener(
                "load",
                async () => {
                    try {
                        const response = await fetch(`${Session.getDomain()}/api/profile/uploadPicture`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ user: userId, picture: reader.result })
                        });

                        const result = await response.json();

                        if (response.ok) {
                            alert('Profile picture updated successfully!');
                            const user = Session.getUser();
                            user._profilePicture = reader.result;
                            Session.setUser(user);
                            window.location.reload();
                        } else {
                            alert(result.message || `Failed to update profile picture`);
                        }
                    } catch (e) {
                        console.error('Error:', e);
                        alert('An error occurred while updating the profile.');
                    }
                },
                false,
            );
        }
    }



    return (
        <>
            {logged === false && (window.location.href = '/')}

            <Header />
            <div id='profile'>
                <div className='profile-points'>
                    <h3 style={{ textAlign: "center" }}>Incentives</h3>
                    {
                        (sponsorIncentives && sponsorIncentives.length > 0) ? (
                            sponsorIncentives.map((item, key) => {
                                return (
                                    <div key={key} className='point_item'>
                                        <p>{item.incentive} <span >{item.point_value}</span> Points   </p>
                                    </div>
                                );
                            })
                        ) : (
                            <div className='point_item'>
                                <p>No Incentives</p>
                            </div>
                        )
                    }
                </div>

                <div className="profile-container">
                    <h2 className="profile-item">
                        <span className="profile-label">Profile picture:</span>
                        {
                            <div className="profile-value">
                                <form onSubmit={uploadImage}>
                                    <input
                                        required
                                        accept="image/*"
                                        style={{ border: "none" }}
                                        id='profilePicture'
                                        name="profilePicture"
                                        className='form-control-inline'
                                        onChange={handleChange}
                                        type="file"
                                    />
                                    <button style={{ backgroundColor: '#007bff' }} className="btn btn-success confirm-btn">Upload photo</button>
                                </form>

                            </div>
                        }
                    </h2>

                    <h2 className="profile-item">
                        <span className="profile-label">Username:</span>
                        {isEditing.username ? (
                            <div className="profile-value">
                                <input
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    className="form-control-inline"
                                    type="text"
                                />
                                <button onClick={() => updateField('username')} className="btn btn-success confirm-btn">Confirm</button>
                            </div>
                        ) : (
                            <div className="profile-value">
                                <span>{backendData.username || "loading..."}</span>
                                <button onClick={() => toggleEdit('username')} className="btn btn-primary change-btn">Change Username</button>
                            </div>
                        )}
                    </h2>

                    <h2 className="profile-item">
                        <span className="profile-label">Email:</span>
                        {isEditing.email ? (
                            <div className="profile-value">
                                <input
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="form-control-inline"
                                    type="email"
                                />
                                <button onClick={() => updateField('email')} className="btn btn-success confirm-btn">Confirm</button>
                            </div>
                        ) : (
                            <div className="profile-value">
                                <span>{backendData.email || "loading..."}</span>
                                <button onClick={() => toggleEdit('email')} className="btn btn-primary change-btn">Change Email</button>
                            </div>
                        )}
                    </h2>

                    <h2 className="profile-item">
                        <span className="profile-label">2FA:</span>

                        {userTFA ? (
                            <div className="profile-value">
                                <span>{'ON'}</span>
                                <button style={{ backgroundColor: 'red' }} onClick={() => toggleTFA(false)} className="btn btn-success confirm-btn">Opt Out</button>
                            </div>
                        ) : (
                            <div className="profile-value">
                                <span>{'OFF'}</span>
                                <button style={{ backgroundColor: 'green' }} onClick={() => toggleTFA(true)} className="btn btn-primary change-btn">Opt In</button>
                            </div>
                        )}
                    </h2>
                    <hr></hr>
                    <h2 className="profile-item">
                        {isEditing.password ? (
                            <div className="profile-value">
                                <form onSubmit={handlePasswordSubmit} className="password-form">
                                    <div className="profile-value">
                                        <span className="profile-label">New Password:</span>
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            className="form-control-inline"
                                            pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                                            title="Password must be at least 8 characters long, contain at least one letter, one number, and one special character."
                                            required
                                        />
                                    </div>
                                    <div className="profile-value">
                                        <span className="profile-label">Confirm Password:</span>
                                        <input
                                            type="password"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            className="form-control-inline"
                                            pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                                            title="Password must be at least 8 characters long, contain at least one letter, one number, and one special character."
                                            required
                                        />
                                    </div>
                                    <button onClick={() => updateField('password')} className="btn btn-success confirm-btn" margin="15px">Confirm</button>
                                </form>
                            </div>
                        ) : (
                            <div className="profile-value">
                                <button style={{ backgroundColor: 'red' }} onClick={() => toggleEdit('password')} className="btn btn-primary change-btn">Change Password</button>
                            </div>
                        )}
                    </h2>

                    {/* driver-specific fields */}
                    {Session.getUser()?._accountType === 1 && (
                        <>
                            <hr />


                            <h2 className="profile-item">
                                <span className="profile-label">Sponsor:</span>
                                <div className="profile-value">
                                    <select
                                        onChange={updateSetSponsor}
                                        name="selectSponsor"
                                        id="selectSponsor"
                                        value={formData.sponsor_id}
                                    >
                                        {
                                            (getSponsors && getSponsors.length > 0) ? (
                                                getSponsors.map((item, key) => {
                                                    return (
                                                        <option key={key} value={item.sponsor_id}>
                                                            {item.sponsor_name}
                                                        </option>
                                                    );
                                                })
                                            ) : (
                                                <option>No sponsors available</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </h2>

                            <h2 className="profile-item">
                                <span className="profile-label">Driver Points:</span>
                                <div className="profile-value">
                                    <span>{formData.driver_points || "0"}</span>
                                </div>
                            </h2>
                        </>
                    )}



                </div>

                <div className='profile-points'>
                    <h3 style={{ textAlign: "center" }}>Point History</h3>
                    {
                        (PointHistory && PointHistory.length > 0) ? (
                            PointHistory.map((item, key) => {
                                const date = new Date(item.date);
                                const formattedDate = date.toLocaleString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                });
                                return (
                                    <div key={key} className='point_item'>
                                        <p>{item.point_value} points on {formattedDate}
                                            <br />
                                            Sponsor Message: {item.message}
                                        </p>
                                    </div>
                                );
                            })
                        ) : (
                            <div className='point_item'>
                                <p>No point history</p>
                            </div>
                        )
                    }

                </div>




            </div>
        </>
    );
}

export default Profile;
