import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import ThemeSwitch from '../components/ThemeSwitch';
import session from '../components/Session';


function About() {



    const [backendData, setBackendData] = useState({});
    useEffect(() => {
        fetch(`${session.getDomain()}/api/about`)
            .then((response) => response.json())
            .then((data) => {
                setBackendData(data);
            });
    }, []);


    const updateVersion = async (event) => {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
    
        try {
          const response = await fetch(`${session.getDomain()}/api/about`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(Object.fromEntries(formData.entries()))
          });
    
          const result = await response.json();

            alert(result.message);
            window.location.reload();
    
        } catch (e) {
          console.error('Error:', e);
        }
    
      };

    return (
        <>
            <Header />
            <div id="version">
                <h2 >Team Name:{backendData.teamnumber ? backendData.teamnumber : "loading...."}</h2>
                <h2>Sprint: {backendData.sprint ? backendData.sprint : "loading...."} </h2>
                <h2>Released On: {backendData.releasedate ? backendData.releasedate : "loading...."}  </h2>
                <h2>Product name: {backendData.productname ? backendData.productname : "loading...."}  </h2>
                <h4>Description: <p> {backendData.productdescription ? backendData.productdescription : "loading...."} </p>  </h4>
            </div>

            <form id="updateAboutForm" onSubmit={updateVersion}>
                <h1 className="h3 mb-3 fw-normal">Update Sprint info</h1>

                <div className="form-floating">
                    <input name="Sprint" autoComplete='off' type="text" className="form-control" id="floatingInput" required  />
                    <label htmlFor="floatingInput">Sprint version:</label>
                </div>

                <div style={{marginTop: "15px"}} className="form-floating">

                    <input name="Released" autoComplete='off' type="text"  className="form-control" id="floatingInput" required/>
                    <label htmlFor="floatingInput">Released Date:</label>
                </div>
                <button style={{marginTop: "15px"}} className="btn btn-primary w-100 py-2" type="submit">Update Info</button>
            </form>

        </>
    );
}


export default About;
