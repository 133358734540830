import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Session from '../components/Session';
import './SponsorDetails.css';

const SponsorDetails = () => {
    const { sponsor_id } = useParams();
    const [sponsor, setSponsor] = useState(null);
    const [sponsorUsers, setSponsorUsers] = useState([]);
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
    const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);

    const [isAddPointsOpen, setIsAddPointsOpen] = useState(false);




    const [addSearchQuery, setAddSearchQuery] = useState('');
    const [removeSearchQuery, setRemoveSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchSponsorDetails = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}`);
                const data = await response.json();
                setSponsor(data);
                await fetchSponsorUsers();
            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }
        };
        fetchSponsorDetails();



    }, [sponsor_id]);

    const fetchSponsorUsers = async () => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/SponsorUsers`);
            const data = await response.json();
            setSponsorUsers(data);
        } catch (error) {
            console.error('Error fetching sponsor users:', error);
        }
    };

    const handleAddSearchChange = async (e) => {
        const value = e.target.value;
        setAddSearchQuery(value);
        setErrorMessage('');
        if (value) {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/addUser/search?username=${value}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setErrorMessage('Error fetching suggestions.');
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleRemoveSearchChange = async (e) => {
        const value = e.target.value;
        setRemoveSearchQuery(value);
        setErrorMessage('');
        if (value) {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/removeUser/search?username=${value}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setErrorMessage('Error fetching suggestions.');
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleUserClick = (user) => {
        setSelectedUser(user);
        setAddSearchQuery(user.username);
        setRemoveSearchQuery(user.username);
        setSuggestions([]);
    };

    const handleAddUser = async () => {
        if (!selectedUser) {
            setErrorMessage("User not found.");
            return;
        }
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/addUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: selectedUser.user_id }),
            });
            if (response.ok) {
                setErrorMessage('');
                setAddSearchQuery('');
                setSelectedUser(null);
                await fetchSponsorUsers();
            } else {
                setErrorMessage("Error adding user.");
            }
        } catch (error) {
            console.error("Error adding user:", error);
            setErrorMessage("Error adding user.");
        }
    };

    const handleOpenAddUserModal = () => {
        setIsAddUserModalOpen(true);
        setAddSearchQuery('');
        setSuggestions([]);
        setErrorMessage('');
    };

    const handleCloseAddUserModal = () => {
        setIsAddUserModalOpen(false);
        setAddSearchQuery('');
        setSelectedUser(null);
        setSuggestions([]);
    };

    const handleOpenRemoveUserModal = () => {
        setIsRemoveUserModalOpen(true);
        setRemoveSearchQuery('');
        setSuggestions([]);
        setErrorMessage('');
    };

    const handleRemoveUser = async () => {
        if (!selectedUser) {
            setErrorMessage("User not found.");
            return;
        }
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/removeUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: selectedUser.user_id }),
            });
            if (response.ok) {
                setErrorMessage('');
                setRemoveSearchQuery('');
                setSelectedUser(null);
                await fetchSponsorUsers();
            } else {
                setErrorMessage("Error removing user.");
            }
        } catch (error) {
            console.error("Error removing user:", error);
            setErrorMessage("Error removing user.");
        }
    };

    const handleCloseRemoveUserModal = () => {
        setIsRemoveUserModalOpen(false);
        setRemoveSearchQuery('');
        setSelectedUser(null);
        setSuggestions([]);
    };


    async function toggleAllClasses() {
        const listItems = document.querySelectorAll('.userSponsors');
        listItems.forEach(item => {
            item.classList.toggle('colorSponsorUser');
            item.classList.toggle('colorSponsorUserClicked');
        });


    }


    async function handlePointEvent(event) {
        event.preventDefault();
        var userList = []
        var giftAmount = document.getElementById('giftAmount').value
        var giftMessage = document.getElementById('giftMessage').value

        const listItems = document.querySelectorAll('.colorSponsorUserClicked');
        listItems.forEach(item => {
            userList.push(item.value);
        });
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/adjustUserPoints`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_list: userList, adjust_amount: giftAmount, message: giftMessage, sponsor_id: sponsor_id }),

            });
            if (response.ok) {
                alert("Adjusted Points")
            } else {
                setErrorMessage("Error Adjusting Points.");
            }
        } catch (error) {
            console.error("Error removing user:", error);
            setErrorMessage("Error removing user.");
        }
    }





    async function generateLink(sponsor) {
        try {
            const response = await fetch(`${Session.getDomain()}/api/authentication/generateToken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userID: sponsor.sponsor_id }),
            });
            const result = await response.json();
            var URL = `${Session.getDomain()}/AccountCreation?Token=${result.token}&sponsorID=${sponsor.sponsor_id}&sponsorName=${sponsor.sponsor_name}`
            var SponsorURLBtn = document.getElementById("SponsorURLBtn");
            navigator.clipboard.writeText(URL).then(function () {
                SponsorURLBtn.innerHTML = "Copied!";
                setTimeout(function () {
                    SponsorURLBtn.innerHTML = "Copy Invitation link";
                }, 1000);

            }, function (err) {
                console.error('Failed to copy text: ', err);
            })

        } catch (error) {
            console.error(error);
        }
    }

    if (!sponsor) {
        return <div>Loading sponsor details...</div>;
    }




    const placeholderImage = 'https://via.placeholder.com/150';

    return (
        <>
            <Header />
            <div className="sponsor-details-container">
                <h1 className="sponsor-name">{sponsor.sponsor_name}</h1>
                <p className="sponsor-description">
                    {sponsor.sponsor_description || 'No description available.'}
                </p>

                <div className="sponsor-users-header">Sponsor Users</div>
                <div className="sponsor-users-section">
                    <div className="sponsor-users-list-container">

                        {sponsorUsers.length > 0 ? (
                            <ul className="sponsor-users-list">

                                {sponsorUsers.map(user => (
                                    <li key={user.user_id} className="sponsor-user-item">
                                        {user.username} - {user.email}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No sponsor users found.</p>
                        )}
                    </div>

                    <div className="sponsor-users-actions">
                        <button className="add-sponsor-user-button" onClick={handleOpenAddUserModal}>Add Sponsor User</button>
                        <button className="add-sponsor-user-button" onClick={() => { setIsAddPointsOpen(true) }}>Gift / Remove driver points</button>
                        <button id='SponsorURLBtn' className="add-sponsor-user-button" onClick={() => { generateLink(sponsor) }}>Copy Invitation link</button>
                        <button className="remove-sponsor-user-button" onClick={handleOpenRemoveUserModal}>Remove Sponsor User</button>
                        <button id='SponsorCatalogbtn' className="add-sponsor-user-button" onClick={() => { navigate(`/catalogManagement/${sponsor_id}`); }}>Manage Catalog</button>
                    </div>
                </div>

                <img
                    src={sponsor.logo ? sponsor.logo : placeholderImage}
                    alt={sponsor.sponsor_name || 'Sponsor Logo'}
                    className="sponsor-logo"
                />

                {/* Add Sponsor User Modal */}
                {isAddUserModalOpen && (
                    <div className="modal open">
                        <div className="modal-overlay" onClick={handleCloseAddUserModal}></div>
                        <div className="modal-content">
                            <button className="modal-close" onClick={handleCloseAddUserModal}>x</button>
                            <h2>Add Sponsor User</h2>

                            <div className="search-container">
                                <input
                                    type="text"
                                    placeholder="Search username"
                                    value={addSearchQuery}
                                    onChange={handleAddSearchChange}
                                    className="search-input"
                                />
                                {suggestions.length > 0 && (
                                    <ul className="suggestions-dropdown">
                                        {suggestions.map((user) => (
                                            <li key={user.user_id} onClick={() => handleUserClick(user)}>
                                                {user.username}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            <button onClick={handleAddUser}>Add User</button>
                        </div>
                    </div>
                )}

                {isRemoveUserModalOpen && (
                    <div className="modal open">
                        <div className="modal-overlay" onClick={handleCloseRemoveUserModal}></div>
                        <div className="modal-content">
                            <button className="modal-close" onClick={handleCloseRemoveUserModal}>x</button>
                            <h2>Remove Sponsor User</h2>

                            <div className="search-container">
                                <input
                                    type="text"
                                    placeholder="Search username"
                                    value={removeSearchQuery}
                                    onChange={handleRemoveSearchChange}
                                    className="search-input"
                                />
                                {suggestions.length > 0 && (
                                    <ul className="suggestions-dropdown">
                                        {suggestions.map((user) => (
                                            <li key={user.user_id} onClick={() => handleUserClick(user)}>
                                                {user.username}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            <button onClick={handleRemoveUser}>Remove User</button>
                        </div>
                    </div>
                )}

                {isAddPointsOpen && (
                    <div className="modal open">
                        <div className="modal-overlay" onClick={() => { setIsAddPointsOpen(false) }}></div>
                        <div className="modal-content">
                            <button className="modal-close" onClick={() => { setIsAddPointsOpen(false) }}>x</button>
                            <h2>Gift / Remove driver points</h2>
                            <form id='pointForm' onSubmit={handlePointEvent} >
                                <ul class="User-dropdown">
                                    {sponsorUsers.map((item, index) => (
                                        <li key={index}
                                            value={item.user_id}
                                            className='colorSponsorUser userSponsors'
                                            onClick={(event) => {
                                                event.currentTarget.classList.toggle('colorSponsorUser');
                                                event.currentTarget.classList.toggle('colorSponsorUserClicked');
                                            }}>
                                            {item.username}
                                        </li>
                                    ))}
                                </ul>



                                <div >
                                    <div className='inputPointField'>
                                        <input autoComplete='off' required id='giftMessage' placeholder='Message' type='text' />
                                        <button>Submit</button>
                                    </div>

                                    <div className='inputPointField'>
                                    <input required id='giftAmount' placeholder='Amount to add/subtract' type="number" step="1" />
                                    <button type="button" onClick={toggleAllClasses}>Select All</button>
                                    </div>

                                </div>

                            </form>

                        </div>
                    </div>
                )}
            </div>

        </>
    );
};

export default SponsorDetails;
