import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import ThemeSwitch from '../components/ThemeSwitch';
import Session from '../components/Session';
import { Button, Modal, Box } from '@mui/material';

function OrderHistory() {
    const [OrderData, setOrderData] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const userId = Session.getUser()?._userId;

    useEffect(() => {
        const fetchOrderHistory = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/profile/getOrderHistory`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userID: userId }),
                });

                if (response.ok) {
                    const result = await response.json();
                    setOrderData(result); // Assuming result is an array
                } else {
                    console.error('Failed to fetch order history');
                }
            } catch (error) {
                console.error('Error fetching order history:', error);
            }
        };

        if (userId) {
            fetchOrderHistory();
        }
    }, [userId]);

    const handleOpen = (order) => {
        setSelectedOrder(order);
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        setSelectedOrder(null);
      };
    
      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${month}/${day}/${year}`;
    };

    // Dummy function. Assumes 2 weeks of shipping time
    const formattedEstimatedArrivalDate = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 14);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${month}/${day}/${year}`;
    };

    const isOrderArrived = (dateString) => {
        const currentDate = new Date();
        const estimatedArrivalDate = new Date(dateString);
        estimatedArrivalDate.setDate(estimatedArrivalDate.getDate() + 14);
        return currentDate >= estimatedArrivalDate;
    };

    return (
        <>
          <Header />
          <div id='orderHistoryContainer'>
                {OrderData.length > 0 ? (
                    OrderData.map(item => (
                        <div key={item.catalog_item_id} className="orderItem">
                            <h6>{item.item_name}</h6>
                            <img src={item.image_url} alt={item.item_name} />
                            <p>Price: ${item.price}</p>
                            <a href={item.item_url} target="_blank" rel="noopener noreferrer">
                                View Item
                            </a>
                            <br></br>
                            {isOrderArrived(item.ordered_at) ? (
                                <Button variant="contained" color="success" disabled>
                                    Order Arrived
                                </Button>
                            ) : (
                                <Button variant="contained" color="primary" onClick={() => handleOpen(item)}>
                                    Order on the way: Open Order Details
                                </Button>
                            )}
                        </div>
                    ))
                ) : (
                    <h2>No orders to see</h2>
                )}
            </div>
    
          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
            {selectedOrder && (
                <>
                <h2>{selectedOrder.item_name}</h2>
                <p>Price: ${selectedOrder.price}</p>
                <p>Order Date: {formatDate(selectedOrder.ordered_at)}</p>
                <p>Estimated Arrival: {formattedEstimatedArrivalDate(selectedOrder.ordered_at)}</p>
                </>
            )}
            </Box>
        </Modal>
        </>
      );
}

export default OrderHistory;
