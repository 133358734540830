import React, { useEffect, useState } from 'react';
import Session from './Session';
import './RemoveRewardsPlanModal.css';

const RemoveRewardsPlanModal = ({ closeModal, sponsor_id }) => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [rewardsPlans, setRewardsPlans] = useState([]); 

    useEffect(() => {
        const fetchSponsorDetails = async () => {
            try {
                await fetchRewardsPlans();
            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }
        };
        fetchSponsorDetails();
    }, [sponsor_id]);

    const fetchRewardsPlans = async () => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/rewardsPlans/${sponsor_id}`);
            const data = await response.json();
            setRewardsPlans(data);
            console.log("All plan data:", data);
        } catch (error) {
            console.error('Error fetching rewards plans:', error);
        }
    };

    const removeRewardsPlan = async () => {
        console.log("Deleting... ", selectedPlan);
        const plan_id = selectedPlan.rewards_plan_id;
        if (!plan_id) {
            alert('No plan was selected');
        }
        try {
            const response = await fetch(`${Session.getDomain()}/api/rewardsPlans/removePlan`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sponsor_id: sponsor_id, plan_id: plan_id }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to deactivate rewards plan');
            }

            const data = await response.json();
            console.log('Rewards plan deactivated successfully:', data.plan);
            alert('Rewards plan deactivated successfully!');

            closeModal(); 

        } catch (error) {
            console.error('Error deactivating rewards plan:', error);
            alert('An error occurred while deactivating the rewards plan');
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <button className="modal-close" onClick={closeModal}>x</button>
                <h2>Select Rewards Plan to Remove</h2>
                <ul className="rewards-plan-list">
                    {rewardsPlans.map(plan => (
                        <li
                            key={plan.rewards_plan_id}
                            onClick={() => setSelectedPlan(plan)}
                            className={`rewards-plan-item ${selectedPlan === plan ? 'selected' : ''}`}
                        >
                            {plan.point_value} pts / {plan.payment_interval} weeks
                        </li>
                    ))}
                </ul>
                <div className="modal-buttons">
                    <button onClick={removeRewardsPlan} disabled={!selectedPlan} className="submit-button">Remove Plan</button>
                    <button onClick={closeModal} className="cancel-button">Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default RemoveRewardsPlanModal;
