import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Session from '../components/Session';
import './ManageSponsorDrivers.css';
import User from '../models/user';
import SponsorUser from '../models/sponsoruser';
import DriverUser from '../models/driveruser';







const DriverPerspective = () => {
    const navigate = useNavigate();
    const { sponsor_id } = useParams();
    const [sponsor, setSponsor] = useState(null);
    const [sponsorDrivers, setSponsorDrivers] = useState([]);
    useEffect(() => {
        const fetchSponsorDetails = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}`);
                const data = await response.json();
                setSponsor(data);
                await fetchSponsorDrivers();
            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }
        };
        fetchSponsorDetails();
    }, [sponsor_id]);

    const fetchSponsorDrivers = async () => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/SponsorDrivers`);
            const data = await response.json();
            setSponsorDrivers(data);
        } catch (error) {
            console.error('Error fetching sponsor drivers:', error);
        }
    };

    if (!sponsor) {
        return <div>Loading sponsor details...</div>;
    }



    var userId;
    var userName;
    var Email;
    var accountType;
    var ProfilePicture;
    var TFA;
    console.log(Session.getUser()._accountType);
    const authenticate = async (userLogged) => {

        var sponsor_name;

        if (Session.getUser()._accountType == 3) {
            sponsor_name = "Admin user: "+ Session.getUser()._username;
        }
        else {
            sponsor_name = "Sponsor user: "+ sponsor.sponsor_name;
        }

        try {
            const response = await fetch(`${Session.getDomain()}/api/authentication/AdminLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: userLogged, sponsor_name: sponsor_name })
            });

            const result = await response.json();

            if (result.found === true) {
                userId = result.userId;
                userName = result.username;
                Email = result.email;
                accountType = result.accountType;
                TFA = result.TwoFA;
                ProfilePicture = result.ProfilePicture;


                const user = new User(result.userId, result.username, result.email, result.accountType, result.TwoFA, result.ProfilePicture);
                Session.setUser(user);
                Session.setLoggedIn(true);
                if (user.accountType === 1) {
                    // Get the driver user object.
                    try {
                        const userResponse = await fetch(`${Session.getDomain()}/api/getuserdata/driveruser?userId=${encodeURIComponent(user.userId)}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });

                        const userResult = await userResponse.json();
                        if (userResult.found === true) {
                            const driverUser = new DriverUser(userResult.sponsorId, userResult.points, userResult.driverId);
                            Session.setDriverUser(driverUser);
                        }
                    }
                    catch (e) {
                        console.error('Error:', e);
                    }
                }
                else if (user.accountType === 2) {
                    // Get the sponsor user object.
                    try {
                        const userResponse = await fetch(`${Session.getDomain()}/api/getuserdata/sponsoruser?userId=${encodeURIComponent(user.userId)}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });

                        const userResult = await userResponse.json();

                        if (userResult.found === true) {
                            const sponsorUser = new SponsorUser(userResult.sponsorId, userResult.sponsorUserId, userResult.status);
                            Session.setSponsorUser(sponsorUser);
                        }
                    }
                    catch (e) {
                        console.error('Error:', e);
                    }

                }
                navigate('/');


            }
            else {
                alert(result.message)
            }

        } catch (e) {
            console.error('Error:', e);
        }

    };

    return (
        <>
            <Header />

            <div id="DriverPerspectiveContainer">


                <div id='driverSelectionList' className="sponsor-details-container">
                    <h1 style={{ textAlign: "center", width: "100%" }} className="sponsor-name">{sponsor.sponsor_name}</h1>
                    <div className="sponsor-users-header">Click on driver to log into their account</div>
                    <div style={{ textAlign: "center", width: "100%" }} className="sponsor-users-section">
                        <div className="sponsor-users-list-container">

                            {
                                sponsorDrivers.map(user => (
                                    <li style={{ cursor: "pointer", listStyleType: "none" }}
                                        onClick={() => authenticate(user.username)}
                                        key={user.user_id}
                                        className="sponsor-user-item"
                                    >
                                        {user.username} - {user.email}
                                    </li>
                                ))
                            }
                        </div>
                    </div>
                </div>


            </div>

        </>
    );
};

export default DriverPerspective;