import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Session from '../components/Session';
import './ManageSponsor.css';
import LabeledIconButton from '../components/LabeledIconButton';
import { Modal, Box, Button, Typography } from '@mui/material';

const ManageSponsor = () => {
    const { sponsor_id } = useParams();
    const [sponsor, setSponsor] = useState(null);
    const [promoCodes, setPromoCodes] = useState(null);
    const [showPointValueChangeModal, setShowPointValueChangeModal] = useState(false);
    const [isAddPointsOpen, setIsAddPointsOpen] = useState(false);
    const [ShowGiftPointsModalOpen, setShowGiftPointsModalOpen] = useState(false);
    const [ShowPromoCodeModal, setPromoCodeModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [sponsorDrivers, setSponsorDrivers] = useState([]);
    const pointDollarRef = useRef();
    const navigate = useNavigate();
    const userClass = Session.getUser()?._accountType;

    useEffect(() => {
        const fetchSponsorDetails = async () => {

            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/SponsorDrivers`);
                const data = await response.json();
                setSponsorDrivers(data);
            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }

            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}`);
                const data = await response.json();
                setSponsor(data);

            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }

            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/getSponsorPromoCodes`);
                const data = await response.json();
                setPromoCodes(data.promo_codes);
            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }
        };


        fetchSponsorDetails();


    }, [sponsor_id]);

    if (!sponsor) {
        return <div>Loading sponsor details...</div>;
    }

    const placeholderImage = 'https://via.placeholder.com/150';

    // Modal Style
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleOpenPointValueChangeModal = () => {
        setShowPointValueChangeModal(true);
    }

    const handleClosePointValueChangeModal = () => {
        setShowPointValueChangeModal(false);
    }

    const ShowGiftPointsModal = () => {
        if (ShowGiftPointsModalOpen) {
            setShowGiftPointsModalOpen(false);
        }
        else {
            setShowGiftPointsModalOpen(true);
        }
    }



    const TogglePromoCodeModal = () => {
        if (ShowPromoCodeModal) {
            setPromoCodeModal(false);
        }
        else {
            setPromoCodeModal(true);
        }
    }

    async function toggleAllClasses() {
        const listItems = document.querySelectorAll('.userSponsors');
        listItems.forEach(item => {
            item.classList.toggle('colorSponsorUser');
            item.classList.toggle('colorSponsorUserClicked');
        });


    }

    async function handlePointEvent(event) {
        event.preventDefault();
        var userList = []
        var giftAmount = document.getElementById('giftAmount').value
        var giftMessage = document.getElementById('giftMessage').value

        const listItems = document.querySelectorAll('.colorSponsorUserClicked');
        listItems.forEach(item => {
            userList.push(item.value);
        });
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/adjustUserPoints`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_list: userList, adjust_amount: giftAmount, message: giftMessage, sponsor_id: sponsor_id }),

            });
            if (response.ok) {
                alert("Adjusted Points")
            } else {
                setErrorMessage("Error Adjusting Points.");
            }
        } catch (error) {
            console.error("Error removing user:", error);
            setErrorMessage("Error removing user.");
        }
    }


    async function removePromoCode(promoCode) {
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/deletePromoCode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ promo_code: promoCode, sponsor_id: sponsor_id }),

            });

            const data = await response.json();
            if (response.ok) {
                const response1 = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/getSponsorPromoCodes`);
                const data1 = await response1.json();
                setPromoCodes(data1.promo_codes);


            } else {
                setErrorMessage("Error adding code.");
            }

        } catch (error) {
            console.error("Error removing code.", error);
            setErrorMessage("Error removing code.");
        }



    }




    async function addPromoCode(event) {
        event.preventDefault();
        var promoCode = document.getElementById('promoCode').value
        var promoCodeDiscount = document.getElementById('promoCodeDiscount').value;
        promoCodeDiscount = parseFloat(promoCodeDiscount) / 100;

        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/addPromoCode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ promo_code: promoCode, discount: promoCodeDiscount, sponsor_id: sponsor_id }),

            });
            const data = await response.json();
            if (response.ok) {
                alert(data.message)
                const response1 = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/getSponsorPromoCodes`);
                const data1 = await response1.json();
                setPromoCodes(data1.promo_codes);


            } else {
                setErrorMessage("Error adding code.");
            }

        } catch (error) {
            console.error("Error adding code.", error);
            setErrorMessage("Error adding code.");
        }
    }


    const handleSubmitPointValueChange = async () => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/adjustPointValue`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newPointValue: pointDollarRef.current.value }),
            });
            const result = await response.json();
            if (result.success) {
                alert("Price updated successfully");
                setShowPointValueChangeModal(false);
                setSponsor((prevSponsor) => ({
                    ...prevSponsor,
                    point_dollar_value: pointDollarRef.current.value,
                }));
            }
            else {
                alert("Error updating price");
            }

        } catch (error) {
            console.error(error);
        }
    }

    if (userClass !== 2 && userClass !== 3) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <h1>Page Not Found</h1>
            </div>
        );
    }

    return (
        <>
            <Header />
            <div className="sponsor-details-container">
                <h1 className="sponsor-name">{sponsor.sponsor_name}</h1>
                <p className="sponsor-description">
                    {sponsor.sponsor_description || 'No description available.'}
                </p>

                <img
                    src={sponsor.logo ? sponsor.logo : placeholderImage}
                    alt={sponsor.sponsor_name || 'Sponsor Logo'}
                    className="sponsor-logo"
                />
            </div>

            <hr class="spaced-hr"></hr>

            <div className="management-button-container">
                <div className="row">
                    <LabeledIconButton
                        label='Manage Sponsor Users'
                        icon='group'
                        link='./sponsorUsers'
                    >
                    </LabeledIconButton>

                    <LabeledIconButton
                        label='Manage Sponsor Drivers'
                        icon='local_shipping'
                        link='./sponsorDrivers'
                    >
                    </LabeledIconButton>

                    <LabeledIconButton
                        label='View Driver Applications'
                        icon='content_copy'
                        link='./driverApplicationInbox'
                    >
                    </LabeledIconButton>

                    <LabeledIconButton
                        label='Manage Driver Rewards Plans'
                        icon='payments'
                        link='./rewardsPlans'
                    >
                    </LabeledIconButton>

                </div>

                <div className="row">
                    <LabeledIconButton
                        label='View Catalog'
                        icon='local_convenience_store'
                        link={"./catalog/"}
                    >
                    </LabeledIconButton>

                    <LabeledIconButton
                        label='Manage Catalog'
                        icon='library_add'
                        link={"./catalogManagement/"}
                    >
                    </LabeledIconButton>

                    <LabeledIconButton
                        label='Change Point Value'
                        icon='attach_money'
                        onClick={handleOpenPointValueChangeModal}
                    >
                    </LabeledIconButton>



                    <LabeledIconButton
                        label='Driver perspective'
                        icon='search'
                        link={"./DriverPerspective"}
                    >
                    </LabeledIconButton>


                    <LabeledIconButton
                        label='Gift/Remove driver points'
                        icon='currency_exchange'
                        onClick={ShowGiftPointsModal}

                    >
                    </LabeledIconButton>


                    <Modal
                        open={showPointValueChangeModal}
                        onClose={handleClosePointValueChangeModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>

                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Update Point Value in USD
                            </Typography>
                            <input
                                type="number"
                                step=".50"
                                placeholder={sponsor.point_dollar_value}
                                ref={pointDollarRef}
                                style={{ width: '100%', padding: '10px', marginTop: '20px' }}
                            />
                            <Button className='submitButton' variant="contained" color="success" onClick={() => {
                                handleSubmitPointValueChange();
                            }}>
                                Submit
                            </Button>

                        </Box>
                    </Modal>

                    <Modal
                        open={ShowGiftPointsModalOpen}
                        onClose={ShowGiftPointsModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <h2>Gift / Remove driver points</h2>
                            <form id='pointForm' onSubmit={handlePointEvent} >
                                <ul class="User-dropdown">
                                    {sponsorDrivers.map((item, index) => (
                                        <li key={index}
                                            value={item.user_id}
                                            className='colorSponsorUser userSponsors'
                                            onClick={(event) => {
                                                event.currentTarget.classList.toggle('colorSponsorUser');
                                                event.currentTarget.classList.toggle('colorSponsorUserClicked');
                                            }}>
                                            {item.username}
                                        </li>
                                    ))}
                                </ul>

                                <div >
                                    <div className='inputPointField'>
                                        <input autoComplete='off' required id='giftMessage' placeholder='Message' type='text' />
                                        <button>Submit</button>
                                    </div>

                                    <div className='inputPointField'>
                                        <input required id='giftAmount' placeholder='Amount to add/subtract' type="number" step="1" />
                                        <button type="button" onClick={toggleAllClasses}>Select All</button>
                                    </div>

                                </div>

                            </form>

                        </Box>
                    </Modal>


                    <Modal
                        open={ShowPromoCodeModal}
                        onClose={TogglePromoCodeModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>

                            <h2>Edit promo codes</h2>
                            <form id='pointForm' onSubmit={addPromoCode} >
                                <ul className="promoCodeCSS-dropdown">
                                    {promoCodes && (
                                        promoCodes.map((item, index) => (
                                            <li key={index} className="promoCodeCSS">
                                                <span>{item.promo_code}</span>
                                                <button type="button"
                                                    className="remove-promo-btn"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        removePromoCode(item.promo_code);
                                                    }}
                                                    aria-label="Remove promo code">
                                                    X
                                                </button>
                                                
                                            </li>
                                        ))
                                    )}
                                </ul>

                                <div >
                                    <div className='inputPointField'>
                                        <input placeholder='promo code' id='promoCode' type='text' required
                                            autocomplete="off"></input>
                                        <input
                                            id="promoCodeDiscount"
                                            type="number"
                                            placeholder="Discount rate (%)"
                                            min="0"
                                            max="100"
                                            step="1"
                                            required
                                            autocomplete="off"
                                        />
                                        <button >Submit</button>
                                    </div>
                                </div>
                            </form>
                        </Box>
                    </Modal>


                    <LabeledIconButton
                        label='Audit Logs'
                        icon='folder_open'
                        link='./Report'
                    >
                    </LabeledIconButton>


                    <LabeledIconButton
                        label='Edit promo codes'
                        icon='local_activity'
                        onClick={TogglePromoCodeModal}
                    >
                    </LabeledIconButton>
                </div>

            </div>
        </>
    );
};

export default ManageSponsor;
