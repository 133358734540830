class DriverUser {
    constructor(sponsorId, points, driverId,sponsorName) {
        this._sponsorId = sponsorId;
        this._points = points;
        this._driverId = driverId;
        this._sponsorName = sponsorName;


    }
    get sponsorId() {
        return this._sponsorId;
    }


    get sponsorName() {
        return this._sponsorName;
    }

    get points() {
        return this._points;
    }

    get driverId() {
        return this._driverId;
    }
}

export default DriverUser;