import React, { useState } from 'react';
import Header from '../components/Header';
import ThemeSwitch from '../components/ThemeSwitch';
import Session from '../components/Session';

function Support() {
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    issueType: '',
    Request: ''
  });

  const issueTypes = [
    "Technical Issue",
    "Billing Problem",
    "Account Issue",
    "General Inquiry"
  ];

  const faqs = [
    { 
      question: "How do I purchase products with the points I've accumulated?", 
      answer: "Navigating to the Catalog from the header above will allow you to view the currently available products in the points shop. You can adjust the price filter to better reflect what is currently in your price range. Once you've located the desired producted, select it and add it to your cart!" 
    },
    { 
      question: "How can I alter the settings of my account?", 
      answer: "Clicking on your profile picture in the header above will display a drop down. Navigating to the profile will allow you to see various settings that can be altered such as changing your account's password, email, username, 2 factor authentication and profile picture." 
    },
    { 
      question: "How do I get in contact with my sponsor if I have a question?", 
      answer: "You can contact the your assigned sponsor(s) using the Inbox accessible by clicking your profile icon." 
    },
    { 
      question: "What should I do if I encounter an issue with the site?", 
      answer: "You can contact support using the 'Need Help?' form located to the right of this FAQ. Fill out the relevant information, click submit and a representative will get back to you as soon as possible!" 
    }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${Session.getDomain()}/api/support`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const result = await response.json();
      console.log('Form submitted:', result);

      if (response.ok) {
        setSubmitted(true);
      } else {
        alert("An error occurred while submitting your request.");
      }

    } catch (e) {
      console.error('Error:', e);
    }
  };

  if (submitted) {
    return (
      <>
        <Header />
        <h1 style={{ textAlign: 'center' }}>Thank you, our representatives will reach out to you shortly!
          <br></br>   
        </h1>
      </>
    );
  }

  return (
    <>
      <Header />

      {/* Container for FAQ and Form */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginTop: '20px', padding: '20px' }}>
        
        {/* FAQ Section */}
        <div style={{ flex: '3', marginRight: '20px', padding: '20px', backgroundColor: '#f0f0f0', borderRadius: '10px' }}>
          <h2 style={{ textAlign: 'center' }}>Frequently Asked Questions</h2>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {faqs.map((faq, index) => (
              <li key={index} style={{ marginBottom: '20px' }}>
                <strong>{faq.question}</strong>
                <p>{faq.answer}</p>
              </li>
            ))}
          </ul>
        </div>

        {/* Support Form Section */}
        <div style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <form 
            onSubmit={handleSubmit} 
            style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              width: '300px', 
              padding: '20px', 
              border: '1px solid #ccc', 
              borderRadius: '10px',
              backgroundColor: '#f9f9f9'
            }}
          >
            <h2 style={{ textAlign: 'center' }}>Need Help?</h2>
            <label>
              First Name:
              <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
            </label>
            <label>
              Last Name:
              <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
            </label>
            <label>
              Work Email:
              <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </label>
            <label>
              Phone Number:
              <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
            </label>
            <label>
              Request Type:  
              <select name="issueType" value={formData.issueType} onChange={handleChange} required>
                <option value="" disabled>Select an issue type</option>
                {issueTypes.map((issue, index) => (
                  <option key={index} value={issue}>{issue}</option>
                ))}
              </select>
            </label>
            <label>
              Request:
              <input type="text" name="Request" value={formData.Request} onChange={handleChange} required />
            </label>
            <button type="submit" style={{ marginTop: '10px', padding: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px' }}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Support;
