import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { useParams, useNavigate } from 'react-router-dom';
import Session from '../components/Session';
import { Button, Modal, Box } from '@mui/material';

function Report() {


    const { sponsor_id } = useParams();
    const [isDriverModalOpen, setIsDriverModalOpen] = useState(false);
    const [driverSearchQuery, setDriverSearchQuery] = useState('');
    const [driverSuggestions, setDriverSuggestions] = useState([]);
    const [sponsor, setSponsor] = useState(null);
    const [drivers, setDrivers] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);

    var DriverInfo = [];
    var userInfo = [];

    useEffect(() => {
        const fetchSponsorDetails = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}`);
                const data = await response.json();
                setSponsor(data);
            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }
        };
        const fetchSponsorDrivers = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/getuserdata/sponsorDriverReport?userId=${sponsor_id}`);
                const data = await response.json();
                DriverInfo = await data.Drivers;
                setDrivers(DriverInfo);
            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }
        };
        fetchSponsorDetails();
        fetchSponsorDrivers();
        document.getElementById('pdfFrame').srcdoc= "<h1 style='text-align:center;' style=fontSize: '1px' >Load Audit</h1>";
    }, [sponsor_id]);

    const handleOpenDriverModal = () => {
        setIsDriverModalOpen(true);
        setDriverSearchQuery('');
        setDriverSuggestions([]);
    };

    const handleCloseDriverModal = () => {
        setIsDriverModalOpen(false);
        setDriverSearchQuery('');
        setDriverSuggestions([]);
        setSelectedDriver(null);
    };

    const handleDriverSearchChange = (e) => {
        const value = e.target.value;
        setDriverSearchQuery(value);
        if (value) {
            const filteredDrivers = drivers.filter(driver =>
                driver.username.toLowerCase().includes(value.toLowerCase())
            );
            setDriverSuggestions(filteredDrivers);
        } else {
            setDriverSuggestions([]);
        }
    };

    const handleDriverClick = (driver) => {
        setSelectedDriver(driver);
        setDriverSearchQuery(driver.username);
        setDriverSuggestions([]);
        setIsDriverModalOpen(false);
        showSingleDriverReport(driver);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    async function fetchDriverDetails() {
        try {
            const response = await fetch(`${Session.getDomain()}/api/getuserdata/sponsorDriverReport?userId=${sponsor_id}`);
            const data = await response.json();
            DriverInfo = await data.Drivers;
        } catch (error) {
            console.error('Error fetching sponsor details:', error);
        }

        var doc = new jsPDF({ putOnlyUsedFonts: true });
        doc.setFontSize(20);
        doc.text("Driver Report", doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth("Driver Report") / 2, 20);
        doc.setFontSize(12);
        doc.text(`Sponsor Name: ${sponsor.sponsor_name}`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`Sponsor Name: ${sponsor.sponsor_name}`) / 2, 30);

        if (DriverInfo) {
            const tableBody = DriverInfo.map(user => [
                user.driver_id,
                user.username,
                user.email,
                user.driver_points,
                user.twofa,
                user.failed_logins,
                user.account_locked,
                user.last_login
            ]);
            autoTable(doc, {
                startY: 40,
                head: [['DriverID', 'Username', 'Email', 'Driver Points', 'MFA', 'Failed Logins', 'Account Locked', 'Last Login']],
                body: tableBody,
            });
        }
        else{
        doc.setFontSize(20);
        doc.text(`No drivers`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`No drivers`) / 2, 40);
        }
        var pdfData = doc.output("dataurlstring");
        document.getElementById('pdfFrame').removeAttribute("srcdoc");
        document.getElementById('pdfFrame').src = pdfData;

    };

    async function fetchPasswordAudit() {
        try {
            const response = await fetch(`${Session.getDomain()}/api/getuserdata/sponsorPasswordChangeAudit?sponsorId=${sponsor_id}`);
            const data = await response.json();
            userInfo = await data.users;
        } catch (error) {
            console.error('Error fetching sponsor details:', error);
        }

        var doc = new jsPDF({ putOnlyUsedFonts: true });
        doc.setFontSize(20);
        doc.text("Driver Report", doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth("Driver Report") / 2, 20);
        doc.setFontSize(12);
        doc.text(`Sponsor Name: ${sponsor.sponsor_name}`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`Sponsor Name: ${sponsor.sponsor_name}`) / 2, 30);

        if (userInfo) {
            const tableBody = userInfo.map(record => [
                record.user_id,
                record.username,
                record.message,
                record.audit_timestamp,
            ]);
            autoTable(doc, {
                startY: 40,
                head: [['User ID', 'Username', 'Audit Message', 'Timestamp']],
                body: tableBody,
            });
        }
        else{
        doc.setFontSize(20);
        doc.text(`No drivers`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`No drivers`) / 2, 40);
        }
        var pdfData = doc.output("dataurlstring");
        document.getElementById('pdfFrame').removeAttribute("srcdoc");
        document.getElementById('pdfFrame').src = pdfData;

    };

    async function fetchLoginAttemptAudit() {
        try {
            const response = await fetch(`${Session.getDomain()}/api/getuserdata/sponsorLoginAttemptAudit?sponsorId=${sponsor_id}`);
            const data = await response.json();
            userInfo = await data.users;
        } catch (error) {
            console.error('Error fetching sponsor details:', error);
        }

        var doc = new jsPDF({ putOnlyUsedFonts: true });
        doc.setFontSize(20);
        doc.text("Driver Report", doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth("Driver Report") / 2, 20);
        doc.setFontSize(12);
        doc.text(`Sponsor Name: ${sponsor.sponsor_name}`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`Sponsor Name: ${sponsor.sponsor_name}`) / 2, 30);

        if (userInfo) {
            const tableBody = userInfo.map(record => [
                record.user_id,
                record.username,
                record.message,
                record.audit_timestamp,
            ]);
            autoTable(doc, {
                startY: 40,
                head: [['User ID', 'Username', 'Audit Message', 'Timestamp']],
                body: tableBody,
            });
        }
        else{
        doc.setFontSize(20);
        doc.text(`No drivers`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`No drivers`) / 2, 40);
        }
        var pdfData = doc.output("dataurlstring");
        document.getElementById('pdfFrame').removeAttribute("srcdoc");
        document.getElementById('pdfFrame').src = pdfData;

    };

    function showSingleDriverReport(driver) {
        var doc = new jsPDF({ putOnlyUsedFonts: true });
        doc.setFontSize(20);
        doc.text("Driver Report", doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth("Driver Report") / 2, 20);
        doc.setFontSize(12);
        doc.text(`Sponsor Name: ${sponsor.sponsor_name}`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`Sponsor Name: ${sponsor.sponsor_name}`) / 2, 30);

        if (driver) {
            const tableBody = [
                driver.driver_id,
                driver.username,
                driver.email,
                driver.driver_points,
                driver.twofa,
                driver.failed_logins,
                driver.account_locked,
                driver.last_login
            ];
            autoTable(doc, {
                startY: 40,
                head: [['DriverID', 'Username', 'Email', 'Driver Points', 'MFA', 'Failed Logins', 'Account Locked', 'Last Login']],
                body: [tableBody],
            });
        }
        else {
            doc.setFontSize(20);
            doc.text(`No driver`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`No driver`) / 2, 40);
        }
        var pdfData = doc.output("dataurlstring");
        document.getElementById('pdfFrame').removeAttribute("srcdoc");
        document.getElementById('pdfFrame').src = pdfData;
    };

    return (
        <>
            <Header />
            <div id='reportContainer'>
                <div id='reportBTN'>
                    <button onClick={fetchDriverDetails}><i style={{ fontSize: "35px" }} class="las la-user-friends"></i>
                        <br></br>
                        Driver Audit
                    </button>
                    <button onClick={fetchPasswordAudit}><i style={{ fontSize: "35px" }} class="las la-user-friends"></i>
                        <br></br>
                        Password Change
                    </button>
                    <button onClick={fetchLoginAttemptAudit}><i style={{ fontSize: "35px" }} class="las la-user-friends"></i>
                        <br></br>
                        Login Attempt Audit
                    </button>
                    <button onClick={handleOpenDriverModal}><i style={{ fontSize: "35px" }} class="las la-user-friends"></i>
                        <br></br>
                        Single Driver Audit
                    </button>
                </div>
                <iframe  src="demo_iframe_srcdoc.htm" id="pdfFrame" ></iframe>
            </div>
            <Modal open={isDriverModalOpen} onClose={handleCloseDriverModal}>
                <Box sx={style}>
                    <button className="modal-close" onClick={handleCloseDriverModal}>x</button>
                    <h2>Search Drivers</h2>
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search driver"
                            value={driverSearchQuery}
                            onChange={handleDriverSearchChange}
                            className="search-input"
                        />
                        {driverSuggestions.length > 0 && (
                            <ul className="suggestions-dropdown">
                                {driverSuggestions.map((driver) => (
                                    <li key={driver.driver_id} onClick={() => handleDriverClick(driver)}>
                                        {driver.username}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default Report;
