class SponsorUser {
    constructor(sponsorId, sponsorUserId, status) {
        this._sponsorId = sponsorId;
        this._sponsorUserId = sponsorUserId;
        this._status = status;

    }

    get sponsorId() {
        return this._sponsorId;
    }

    get sponsorUserId() {
        return this._sponsorUserId;
    }

    get status() {
        return this._status;
    }
}

export default SponsorUser;