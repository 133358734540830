import React, { useState } from 'react';
import Session from './Session.js';
import './ManageRewardsPlanDriversModal.css';

const ManageRewardsPlanDriversModal = ({ plan_id, type, closeModal, refreshDrivers }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSearchChange = async (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        if (value) {
            try {
                const endpoint = type === 'add' ? 'addDriver' : 'removeDriver';
                const response = await fetch(`${Session.getDomain()}/api/rewardsPlans/${plan_id}/${endpoint}/search?username=${value}`);
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                setErrorMessage('Error fetching suggestions.');
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleUserClick = (user) => {
        setSelectedUser(user);
        setSearchQuery(user.username);
        setSuggestions([]);
    };

    const handleSubmit = async () => {
        if (!selectedUser) {
            setErrorMessage('User not found.');
            return;
        }

        try {
            const endpoint = type === 'add' ? 'addDriver' : 'removeDriver';
            const response = await fetch(`${Session.getDomain()}/api/rewardsPlans/${plan_id}/${endpoint}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: selectedUser.user_id }),
            });

            if (response.ok) {
                setErrorMessage('');
                setSelectedUser(null);
                closeModal();
                refreshDrivers();
            } else {
                setErrorMessage(`Error ${type === 'add' ? 'adding' : 'removing'} user.`);
            }
        } catch (error) {
            setErrorMessage(`Error ${type === 'add' ? 'adding' : 'removing'} user.`);
        }
    };

    return (
        <div className="modal">
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content">
                <button className="modal-close" onClick={closeModal}>x</button>
                <h2>{type === 'add' ? 'Add Driver' : 'Remove Driver'}</h2>

                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Search username"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                    {suggestions.length > 0 && (
                        <ul className="suggestions-dropdown">
                            {suggestions.map((user) => (
                                <li key={user.user_id} onClick={() => handleUserClick(user)}>
                                    {user.username}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <button onClick={handleSubmit}>{type === 'add' ? 'Add' : 'Remove'} Driver</button>
            </div>
        </div>
    );
};

export default ManageRewardsPlanDriversModal;
