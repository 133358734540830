import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Session from '../components/Session';
import SponsorCard from '../components/SponsorCard';
import RemoveSponsorOrgModal from '../components/RemoveSponsorOrgModal';

import './AdminSponsors.css';

function AdminSponsors() {
    const [sponsors, setSponsors] = useState([]);
    const [showAddSponsorOrgModal, setShowAddSponsorOrgModal] = useState(false);
    const [showRemoveSponsorOrgModal, setShowRemoveSponsorOrgModal] = useState(false);
    const [newSponsorOrg, setNewSponsorOrg] = useState({
        sponsor_name: '',
        sponsor_description: '',
    });
    const [error, setError] = useState('');
    const userClass = Session.getUser()?._accountType;

    useEffect(() => {
        // Redirect to "Page Not Found" if not an admin
        const fetchSponsors = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors`);
                const data = await response.json();
                setSponsors(data);
            } catch (error) {
                console.error('Error fetching sponsors:', error);
            }
        };
        fetchSponsors();
    }, []);

    if (userClass !== 3) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <h1>Page Not Found</h1>
            </div>
        );
    }

    const toggleAddSponsorOrgModal = () => {
        setError('');
        setNewSponsorOrg({ sponsor_name: '', sponsor_description: '' });
        setShowAddSponsorOrgModal(!showAddSponsorOrgModal);
    };

    const handleSponsorOrgInputChange = (e) => {
        const { name, value } = e.target;
        setNewSponsorOrg({ ...newSponsorOrg, [name]: value });
    };

    const handleAddSponsorOrgSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const trimmedSponsorOrg = {
            sponsor_name: newSponsorOrg.sponsor_name.trim(),
            sponsor_description: newSponsorOrg.sponsor_description.trim(),
        };

        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/addSponsor`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(trimmedSponsorOrg),
            });

            if (response.ok) {
                const addedSponsorOrg = await response.json();
                setSponsors([...sponsors, addedSponsorOrg.sponsor]);
                setNewSponsorOrg({ sponsor_name: '', sponsor_description: '' });
                toggleAddSponsorOrgModal();
            } else {
                const errorData = await response.json();
                if (errorData.error === 'Sponsor name already exists') {
                    setError('Sponsor name already exists. Please choose a different name.');
                } else {
                    setError('Error adding sponsor organization.');
                }
            }
        } catch (error) {
            setError('Error adding sponsor organization.');
            console.error('Error adding sponsor organization:', error);
        }
    };

    const toggleRemoveSponsorOrgModal = () => {
        setShowRemoveSponsorOrgModal(!showRemoveSponsorOrgModal);
    };

    const closeRemoveSponsorOrgModal = () => {
        setShowRemoveSponsorOrgModal(false);
        window.location.reload();
    }

    return (
        <>
            <Header />
            <div className="admin-sponsors-container">
                <div className="sponsor-list-container">
                    <div className="sponsor-orgs-header">Sponsor Organizations</div>
                    <div className="sponsor-list">
                        {sponsors.map((sponsor) => (
                            <SponsorCard
                                key={sponsor.sponsor_id}
                                sponsor={sponsor}
                                link={`/manage/sponsor/${sponsor.sponsor_id}`}
                            />
                        ))}
                    </div>
                </div>

                <div className="buttons-container">
                    <button className="add-sponsor-org-btn" onClick={toggleAddSponsorOrgModal}>
                        Add Sponsor Organization
                    </button>
                    <button className="remove-sponsor-org-btn" onClick={toggleRemoveSponsorOrgModal}>
                        Remove Sponsor Organization
                    </button>
                </div>
            </div>

            {showAddSponsorOrgModal && (
                <div className="add-sponsor-org-modal-overlay" onClick={toggleAddSponsorOrgModal}>
                    <div className="add-sponsor-org-modal" onClick={(e) => e.stopPropagation()}>
                        <button className="add-sponsor-org-modal-close-btn" onClick={toggleAddSponsorOrgModal}>X</button>
                        <div className="add-sponsor-org-modal-header">New Sponsor Organization</div>
                        <form onSubmit={handleAddSponsorOrgSubmit}>
                            <label>
                                Sponsor Name:
                                <input
                                    type="text"
                                    name="sponsor_name"
                                    value={newSponsorOrg.sponsor_name}
                                    onChange={handleSponsorOrgInputChange}
                                    required
                                />
                            </label>
                            <label>
                                Sponsor Description:
                                <textarea
                                    name="sponsor_description"
                                    value={newSponsorOrg.sponsor_description}
                                    onChange={handleSponsorOrgInputChange}
                                    required
                                />
                            </label>
                            {error && <p className="error-message"><b>{error}</b></p>} 
                            <button type="submit" className="add-sponsor-org-submit-btn">Add Sponsor</button>
                        </form>
                    </div>
                </div>
            )}
            {showRemoveSponsorOrgModal && (
                <RemoveSponsorOrgModal
                    onClose={closeRemoveSponsorOrgModal}
                />
            )}
        </>
    );
}

export default AdminSponsors;
