// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
    border: 2px solid var(--border-color); /* Border color */
}

.card:hover {
    background-color: var(--header-bg-light); /* Background color on hover */
    color: var(--text-color-light); /* Text color remains consistent */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

.add-item-button, .remove-item-button {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.card:hover .add-item-button, .card:hover .remove-item-button {
    display: inline-block;
}

.add-item-button {
    color: #00ff00;
    right: 40px; 
}

.remove-item-button {
    color: #ff0000; 
    right: 10px; 
}
`, "",{"version":3,"sources":["webpack://./src/components/CatalogItem.css"],"names":[],"mappings":"AAAA;IACI,6EAA6E,EAAE,sBAAsB;IACrG,qCAAqC,EAAE,iBAAiB;AAC5D;;AAEA;IACI,wCAAwC,EAAE,8BAA8B;IACxE,8BAA8B,EAAE,kCAAkC;IAClE,yCAAyC,EAAE,kBAAkB;AACjE;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;IAC3B,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,cAAc;IACd,WAAW;AACf","sourcesContent":[".card {\n    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */\n    border: 2px solid var(--border-color); /* Border color */\n}\n\n.card:hover {\n    background-color: var(--header-bg-light); /* Background color on hover */\n    color: var(--text-color-light); /* Text color remains consistent */\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow effect */\n}\n\n.add-item-button, .remove-item-button {\n    display: none;\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    background: none;\n    border: none;\n    font-size: 1.5rem;\n    cursor: pointer;\n}\n\n.card:hover .add-item-button, .card:hover .remove-item-button {\n    display: inline-block;\n}\n\n.add-item-button {\n    color: #00ff00;\n    right: 40px; \n}\n\n.remove-item-button {\n    color: #ff0000; \n    right: 10px; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
