// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sponsor-details-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.sponsor-logo {
    position: absolute;
    top: 20px;
    right: 20px;
    max-height: 150px;
    max-width: 150px;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.sponsor-name {
    max-width: 85%; 
    font-size: 2.0rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
}

.sponsor-description {
    font-size: 1.0rem;
    line-height: 1.6;
    color: #555;
    max-width: 70%;
    text-align: left;
    margin: 20px 0;
}

.spaced-hr {
    margin-top: 25px;
}

.management-button-container {
    display: flex;
    flex-direction: column;
    gap: 10px; 
}

.row {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
}

    .row .LabeledIconButton {
        display: flex;
        align-items: center;
        justify-content: center;
    }


.submitButton {
    display: block;
    margin: 20px auto 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManageSponsor.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,yCAAyC;AAC7C;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,iBAAiB;AACrB;;IAEI;QACI,aAAa;QACb,mBAAmB;QACnB,uBAAuB;IAC3B;;;AAGJ;IACI,cAAc;IACd,wBAAwB;AAC5B","sourcesContent":[".sponsor-details-container {\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    position: relative;\n}\n\n.sponsor-logo {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n    max-height: 150px;\n    max-width: 150px;\n    object-fit: contain;\n    border-radius: 8px;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n}\n\n.sponsor-name {\n    max-width: 85%; \n    font-size: 2.0rem;\n    font-weight: bold;\n    color: #333;\n    margin-bottom: 10px;\n    text-align: center;\n    text-transform: uppercase;\n}\n\n.sponsor-description {\n    font-size: 1.0rem;\n    line-height: 1.6;\n    color: #555;\n    max-width: 70%;\n    text-align: left;\n    margin: 20px 0;\n}\n\n.spaced-hr {\n    margin-top: 25px;\n}\n\n.management-button-container {\n    display: flex;\n    flex-direction: column;\n    gap: 10px; \n}\n\n.row {\n    display: flex;\n    justify-content: flex-start;\n    margin-left: 10px;\n}\n\n    .row .LabeledIconButton {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n\n\n.submitButton {\n    display: block;\n    margin: 20px auto 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
