import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const LabeledIconButton = ({
    label,
    tooltip,
    color = 'btn-primary',
    disabled = false,
    visible = true,
    width = null,
    icon = null,
    link,
    onClick
}) => {
    const navigate = useNavigate();
    const getButtonClass = () => {
        return 'btn ' + color;
    };
    const getButtonStyle = () => {
        return {
            width: width ? width : '200px',
            margin: '16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px',
            textAlign: 'center'
        };
    };
    const handleClick = () => {
        if (onClick) onClick();
        if (link) navigate(link);
    };
    if (!visible) return null;
    return (
        <button
            type="button"
            className={getButtonClass()}
            disabled={disabled}
            onClick={handleClick}
            style={getButtonStyle()}
            title={tooltip}
        >
            {icon && (
                <span
                    className="material-icons"
                    style={{
                        fontSize: '32px',
                        marginBottom: '8px'
                    }}
                >
                    {icon}
                </span>
            )}
            <span style={{ fontSize: '16px' }}>{label}</span>
        </button>
    );
};

LabeledIconButton.propTypes = {
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    width: PropTypes.string,
    icon: PropTypes.string,
    link: PropTypes.string,
    onClick: PropTypes.func
};

export default LabeledIconButton;

