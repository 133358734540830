import React from 'react';
import logo from '../assets/trucklogo.png';
import Header from '../components/Header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import session from '../components/Session';
import ThemeSwitch from '../components/ThemeSwitch';
import User from '../models/user';


function ResetPassword() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const userId = searchParams.get("id");

    const resetUserPassword = async (event) => {

        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);

        const newPassword = formData.get('password');
        const confirmPassword = formData.get('confirmPassword');

        if(newPassword !== confirmPassword) {
            alert("Passwords do not match.");
            return;
        }
    
        try {
          const response = await fetch(`${session.getDomain()}/api/authentication/resetpassword`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: userId,
                token: token,
                newPassword: newPassword
            })
          });
    
          const result = await response.json();
          console.log(result);
    
          if (result.found === true) {
            // We have that email stored in our DB as a user
            alert("Password Reset!");
          }
          else {
            alert(result.message);
          }
    
        } catch (e) {
          console.error('Error:', e);
        }
    
      };

    return (
    <>
        <Header />
        <form id="signForm" onSubmit={resetUserPassword}>
        <img className="mb-4" src={logo} alt="Logo" width="72" height="57" />
        <h1 className="h3 mb-3 fw-normal">Enter Your New Password</h1>

        <div className="form-floating">
            <input name="password" autoComplete='off' type="password" className="form-control" id="floatingInput" placeholder="password" 
            pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" title="Password must be at least 8 characters long, contain at least one letter, one number, and one special character."
            required />
            <label htmlFor="floatingInput">Password</label>
        </div>

        <div className="form-floating">
            <input name="confirmPassword" autoComplete='off' type="password" className="form-control" id="floatingInput" placeholder="Confirm Password" 
            pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" title="Password must be at least 8 characters long, contain at least one letter, one number, and one special character."
            required/>
            <label htmlFor="floatingInput">Confirm Password</label>
        </div>

        <button className="btn btn-primary w-100 py-2" type="submit">Submit</button>
        <p className="mt-5 mb-3 text-body-secondary">© 2017–2024</p>
        </form>

    </>

    );
}

export default ResetPassword;
